import useValidate from "features/tool/hooks/useValidate";
import { useCustomer } from "providers/customer";
import React, { useState } from "react";
import { useMutation } from "react-query";
import Button from "ui/Button";
import Modal from "ui/Modal";
import SnackBar from "ui/SnackBar";
import Spinner from "ui/Spinner";
import TextField from "ui/TextField";
import api from "utils/api";
import { getErrorMessage } from "utils/parser";
import { ActionsWrapper, Wrapper } from "./styles";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NewCustomerForm({ open, setOpen }: IProps) {
  const initForm = {
    name: "",
    description: "",
    gateway: "",
    primaryDNS: "",
    secondaryDNS: "",
  };
  const [form, setForm] = useState(initForm);
  const { customer } = useCustomer();
  const {
    errors,
    touched,
    handleBlur,
    handleFocus,
    setTouched,
  } = useValidate();

  const mutation = useMutation(
    () =>
      api.post(
        "/vcloud-director/vdc",
        {
          ...form,
          name: customer?.orgPrefix
            ? `${customer.orgPrefix}-${form.name}`
            : form.name,
        },
        {
          headers: {
            "customer-token": customer?.token,
          },
        }
      ),
    {
      onSuccess: () => {
        setOpen(false);
        setForm(initForm);
      },
    }
  );

  const handleChange = ({
    currentTarget: { value, name },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Modal open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
      <Wrapper onSubmit={handleSubmit}>
        {mutation.isError && (
          <SnackBar type="error" message={getErrorMessage(mutation.error)} />
        )}
        {mutation.isLoading ? (
          <Spinner width={50} />
        ) : (
          <>
            <TextField
              name="name"
              value={form.name}
              label="Customer Name"
              autoComplete="off"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors["name"]}
              touched={touched["name"]}
            />
            <TextField
              name="description"
              value={form.description}
              label="Customer Description"
              autoComplete="off"
              onChange={handleChange}
            />
            <TextField
              name="gateway"
              value={form.gateway}
              label="Gateway CIDR"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors["gateway"]}
              touched={touched["gateway"]}
              required
            />
            <TextField
              name="primaryDNS"
              value={form.primaryDNS}
              label="primary DNS"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors["primaryDNS"]}
              touched={touched["primaryDNS"]}
              required
            />
            <TextField
              name="secondaryDNS"
              value={form.secondaryDNS}
              label="Secondary DNS"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={errors["secondaryDNS"]}
              touched={touched["secondaryDNS"]}
              required
            />
            <ActionsWrapper>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </Button>
              <Button variant="outlined" type="submit">
                Create
              </Button>
            </ActionsWrapper>
          </>
        )}
      </Wrapper>
    </Modal>
  );
}
