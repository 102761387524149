import React, { useState } from "react";
import { IDataTableColumn } from "react-data-table-component";
import { IVMRow } from "types/dashboard";
import Button from "ui/Button";
import Checkbox from "ui/Checkbox";
import Modal from "ui/Modal";
import { ActionsWrapper, Label, Row, RowsWrapper, Wrapper } from "./styles";

interface IProps {
  columns: IDataTableColumn<IVMRow>[];
  setColumns: React.Dispatch<React.SetStateAction<IDataTableColumn<IVMRow>[]>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ColumnsSelector({
  columns,
  setColumns,
  open,
  setOpen,
}: IProps) {
  const [unsavedColumns, setUnsavedColumns] = useState(columns);

  const handleChange = ({
    currentTarget: { name, checked },
  }: React.FormEvent<HTMLInputElement>) =>
    setUnsavedColumns(
      unsavedColumns.map((unsavedColumn) =>
        unsavedColumn.name == name
          ? { ...unsavedColumn, omit: !checked }
          : unsavedColumn
      )
    );

  const handleCancel = () => {
    setUnsavedColumns(columns);
    setOpen(false);
  };

  const handleSave = () => {
    setColumns(unsavedColumns);
    setOpen(false);
  };

  return (
    <Modal open={open} closeOnDocumentClick onClose={handleCancel}>
      <Wrapper>
        <RowsWrapper>
          {unsavedColumns.map((one) => (
            <Row key={one.id}>
              <Label>{one.name}</Label>
              <Checkbox
                checked={!one.omit}
                name={one.name + ""}
                onChange={handleChange}
              />
            </Row>
          ))}
        </RowsWrapper>
        <ActionsWrapper>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </ActionsWrapper>
      </Wrapper>
    </Modal>
  );
}
