import DataCenterForm from "features/tool/components/DataCenterForm";
import Layout from "features/tool/components/Layout";
import useValidate from "features/tool/hooks/useValidate";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaSave, FaTrash } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { IDataCenter, IDataCenterForm } from "types/tool";
import Button from "ui/Button";
import api from "utils/api";
import { getRecord } from "utils/parser";
import { DeleteWrapper, Wrapper } from "./styles";

export default function UpdateDataCenter() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const init: IDataCenterForm = { name: "", vCloudURL: "", cpuSpeed: 0};
  const [form, setForm] = useState<IDataCenterForm>(init);

  const {
    errors,
    touched,
    handleBlur,
    handleFocus,
    setTouched,
  } = useValidate();

  //TODO: Filter by data center
  const { data: dataCenter } = useQuery("fetchDataCenter", async () => {
    const response = await api.get(`/tool/datacenters/${id}`);
    return getRecord(response) as IDataCenter;
  });

  useEffect(() => {
    if (dataCenter) {
      const { name, vCloudURL,cpuSpeed } = dataCenter;
      setForm({ name, vCloudURL,cpuSpeed });
    }
  }, [dataCenter]);

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: value });
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const updateMutation = useMutation(
    async () => api.put(`/tool/datacenters/${id}`, form),
    {
      onSuccess: () => {
        history.push("/tool/settings/datacenters");
      },
    }
  );

  const deleteMutation = useMutation(
    async () => api.delete(`/tool/datacenters/${id}`),
    {
      onSuccess: () => {
        history.push("/tool/settings/datacenters");
      },
    }
  );

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateMutation.mutate();
  };

  return (
    <Layout>
      <Wrapper>
        <DeleteWrapper>
          <Button color="secondary" onClick={handleDelete}>
            <FaTrash /> Delete
          </Button>
        </DeleteWrapper>
        <DataCenterForm
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          errors={errors}
          touched={touched}
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        >
          <Button type="submit" color="secondary">
            <FaArrowLeft /> Cancel
          </Button>
          <Button type="submit">
            <FaSave /> Update
          </Button>
        </DataCenterForm>
      </Wrapper>
    </Layout>
  );
}
