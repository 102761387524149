import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDropzone } from "react-dropzone";
import { FaCheckCircle, FaUpload } from "react-icons/fa";

import { useCustomer, useDispatchCustomer } from "providers/customer";
import { ILicense } from "types/tool";
import SnackBar from "ui/SnackBar";
import Spinner from "ui/Spinner";
import api, { on } from "utils/api";
import { getErrorMessage } from "utils/parser";
import {
  LoadingWrapper,
  UploadedSuccessMessage,
  UploadMessage,
  UploadSuccessWrapper,
  UploadTitle,
  UploadWrapper,
  Button,
  Wrapper,
} from "./styles";

const UploadSuccess = () => {
  return (
    <UploadSuccessWrapper>
      <FaCheckCircle />
      <UploadedSuccessMessage>
        Your license has been uploaded successfully
      </UploadedSuccessMessage>
      <Button href="/profile">Check resource allocation</Button>
    </UploadSuccessWrapper>
  );
};

export default function Upload() {
  const [license, setLicense] = useState<ILicense | Record<string, never>>({});
  const { customer } = useCustomer();
  const { setCustomer } = useDispatchCustomer();
  const [message, setMessage] = useState<string>();
  const mutation = useMutation(
    async () => api.post("/tool/upload", { license }),
    {
      onSettled: () => {
        setLicense({});
      },
      onSuccess: ({ data }) => {
        localStorage.setItem("token", license.token);
        setCustomer({ ...data, token: license.token });
      },
    }
  );

  on("message", (msg: string) => setMessage(msg));

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        const parsedResult =
          typeof reader.result === "string" ? JSON.parse(reader.result) : {};
        setLicense(parsedResult);
      };
      reader.readAsText(file);
    });
  }, []);

  useEffect(() => {
    if (license.token) {
      mutation.mutate();
    }
  }, [license]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (mutation.isSuccess || customer) {
    return <UploadSuccess />;
  }

  return (
    <Wrapper>
      {mutation.isError && (
        <SnackBar type="error" message={getErrorMessage(mutation.error)} />
      )}
      {mutation.isLoading ? (
        <LoadingWrapper>
          <Spinner />
          <LoadingWrapper>{message}</LoadingWrapper>
        </LoadingWrapper>
      ) : (
        <UploadWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <FaUpload />
          <UploadTitle>Upload your licenses</UploadTitle>
          <UploadMessage>
            {isDragActive
              ? "Drop the file here "
              : "Click to upload or drag your file here"}
          </UploadMessage>
        </UploadWrapper>
      )}
    </Wrapper>
  );
}
