import styled from "@emotion/styled";

export const Wrapper = styled.form`
  width: 55rem;
  height: 100%;
  background: white;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 1rem;
  justify-content: space-between;
`;

export const PlansWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  flex-wrap: wrap;
`;

export const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 3rem;
  & button:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const CreateFrom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

export const CreateFromItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 3rem;
  }
  label {
    margin-top: 5px;
  }
`;

export const FieldsWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
`