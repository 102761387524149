import { IPlan } from "types/virtual";

export const plans: IPlan[] = [
  {
    type: "small",
    cpu: 1,
    cores: 1,
    memory: 512,
    storage: 32,
  },
  {
    type: "medium",
    cpu: 2,
    cores: 2,
    memory: 1024,
    storage: 64,
  },
  {
    type: "large",
    cpu: 4,
    cores: 4,
    memory: 2048,
    storage: 128,
  },
];
