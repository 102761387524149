import { IData, IErrorMessage } from "types/common";
import { IDesc, IStatsResponse } from "types/dashboard";

export const getErrorMessage = (error: IErrorMessage) => {
  if (!error) return;
  if (!error.code && !error.response?.data.message)
    return "OUPS INTERNAL SERVER ERROR !!!";
  return error.response?.data.message || error.message;
};

export const getRecord = (data: IData) => {
  if (!(typeof data === "object" && "data" in data)) return [];
  if (Array.isArray(data.data)) {
    return data.data;
  }
  return data.data.record || data.data.values || data.data.value || data.data;
};

export const getHardwareStats = (data: IStatsResponse) => {

  return [
    {
      used: data.numberOfPoweredOnVms,
      total: data.numberOfDeployedVApps ,
      type: "VMs",
      quotas: data.numberOfDeployedVApps - data.numberOfPoweredOnVms,
    },
    {
      total: Math.floor((data.payGoVdcSummary.cpuConsumptionMhz + data.reservationPoolVdcSummary.cpuConsumptionMhz) / 1000),
      quotas: Math.floor(data.reservationPoolVdcSummary.cpuConsumptionMhz / 1000),
      type: "CPUs",
      used: Math.floor(data.payGoVdcSummary.cpuConsumptionMhz / 1000),
    },
    {
      total: Math.floor((data.payGoVdcSummary.memoryConsumptionMB + data.reservationPoolVdcSummary.memoryConsumptionMB) / 1024),
      quotas: Math.floor(data.reservationPoolVdcSummary.memoryReservationMB / 1024),
      type: "RAM",
      used: Math.floor(data.payGoVdcSummary.memoryConsumptionMB / 1024),
    },
    {
      total: Math.floor((data.payGoVdcSummary.storageConsumptionMB + data.reservationPoolVdcSummary.storageConsumptionMB) / 1024),
      quotas: Math.floor(data.reservationPoolVdcSummary.storageConsumptionMB / 1024),
      type: "Storage",
      used: Math.floor(data.payGoVdcSummary.storageConsumptionMB / 1024),
    },
  ] as IDesc[];
};

export const getIdFromHref = (href: string) => {
  const exec = /\/([^/]*$)/.exec(href);
  if (exec) return exec[1];
  return null;
};