import React from "react";

type NetworkProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function Network({
  fill = "#000",
  width = 20,
  height = 20,
}: NetworkProps) {
  return (
    <svg
      id="world"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18.363 18.363"
    >
      <g id="Group_18" data-name="Group 18">
        <g id="Group_17" data-name="Group 17">
          <path
            id="Path_59"
            data-name="Path 59"
            d="M9.182,0a9.182,9.182,0,1,0,9.182,9.182A9.176,9.176,0,0,0,9.182,0Zm0,1.076a8.1,8.1,0,0,1,3.068.6l-.465.717a2.51,2.51,0,0,0-.224,2.294l.079.2a1.509,1.509,0,0,1-1.385,2.069L8.5,6.972A2.045,2.045,0,0,0,6.484,9.22L6.8,12.311a.451.451,0,0,1-.816.308L1.548,6.443A8.108,8.108,0,0,1,9.182,1.076ZM11.333,17l-.364-1.109a1.531,1.531,0,0,1,1.454-2.007H12.6a3.051,3.051,0,0,0,1.709-.522l.8-.537a2.181,2.181,0,0,1,1.494-.357A8.138,8.138,0,0,1,11.333,17ZM14.5,11.933l-.8.537a1.979,1.979,0,0,1-1.109.338h-.172a2.608,2.608,0,0,0-2.477,3.418l.324.989a8.223,8.223,0,0,1-1.089.073A8.1,8.1,0,0,1,1.076,9.182a8.2,8.2,0,0,1,.118-1.387l3.912,5.452A1.527,1.527,0,0,0,7.866,12.2L7.554,9.112a.966.966,0,0,1,.952-1.064l1.76-.019a2.588,2.588,0,0,0,2.373-3.545l-.079-.2a1.432,1.432,0,0,1,.128-1.309l.535-.826a8.126,8.126,0,0,1,3.747,9.288A3.251,3.251,0,0,0,14.5,11.933Z"
            fill={fill}
          />
        </g>
      </g>
      <g id="Group_20" data-name="Group 20" transform="translate(9.225 8.456)">
        <g id="Group_19" data-name="Group 19">
          <path
            id="Path_60"
            data-name="Path 60"
            d="M261.954,236.585l-.36-.41a1.23,1.23,0,0,0-1.412-.319l-2.212.949a1.263,1.263,0,0,0-.285,2.132,1.226,1.226,0,0,0,.768.271,1.245,1.245,0,0,0,.272-.03l2.572-.576A1.233,1.233,0,0,0,261.954,236.585Zm-.892.967-2.572.576a.154.154,0,0,1-.132-.031.188.188,0,0,1,.036-.3c2.387-1.024,2.228-.962,2.274-.962a.157.157,0,0,1,.118.053l.36.41A.156.156,0,0,1,261.062,237.552Z"
            transform="translate(-257.222 -235.756)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
