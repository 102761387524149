import React from "react";
import { Wrapper, Header, Body, SpecText } from "./styles";

type PlanCardProps = {
  type: string;
  cpu: number;
  cores: number;
  memory: number;
  storage: number;
  isSelected: boolean;
  handleClick: (e: React.FormEvent<HTMLDivElement>) => void;
};

export default function PlanCard({
  type,
  cpu,
  cores,
  memory,
  storage,
  isSelected,
  handleClick,
}: PlanCardProps) {
  return (
    <Wrapper onClick={handleClick} data-type={type} isSelected={isSelected}>
      <Header>{type}</Header>
      <Body>
        <SpecText>
          {cpu} CPU / {cores} {cores > 1 ? "Cores" : "Core"}
        </SpecText>
        <SpecText>{memory} MB</SpecText>
        <SpecText>{storage} GB</SpecText>
      </Body>
    </Wrapper>
  );
}
