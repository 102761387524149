import React from "react";

import { Input } from "./styles";

interface IProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

export default function RadioInput({ name, value, checked, onChange }: IProps) {
  return (
    <Input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
}
