import styled from "@emotion/styled";

export const Form = styled.form`
  width: 40%;
  margin: auto;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 1rem;
  padding: 3rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button:not(:last-child) {
    margin-right: 2rem;
  }
`;
