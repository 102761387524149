import React from "react";

type VAppProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function VApp({
  fill = "#000",
  width = 20,
  height = 20,
}: VAppProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34.816 34.816"
    >
      <g id="depth" transform="translate(0.001 0.001)">
        <path
          id="Path_53"
          data-name="Path 53"
          d="M34.815,8.973a.68.68,0,0,0-.378-.607L17.709.07a.68.68,0,0,0-.6,0L.377,8.366a.68.68,0,0,0,0,1.216l7.159,3.609L.373,16.8a.68.68,0,0,0,0,1.216l7.214,3.578L.373,25.232a.68.68,0,0,0,0,1.216l16.728,8.3a.68.68,0,0,0,.6,0l5.651-2.8a.68.68,0,0,0-.6-1.218l-5.348,2.652L2.2,25.834l6.914-3.485L17.1,26.312a.68.68,0,0,0,.6,0L25.7,22.349l6.914,3.485-4.378,2.171a.68.68,0,0,0,.6,1.218l5.6-2.775a.68.68,0,0,0,0-1.216l-7.218-3.638,7.214-3.578a.68.68,0,0,0,0-1.216l-7.159-3.609,7.159-3.609a.68.68,0,0,0,.374-.609Zm-2.2,8.429L17.407,24.944,2.2,17.4l6.844-3.45L15.891,17.4l-1.849.924a.68.68,0,0,0,.608,1.216l2.756-1.378,2.756,1.378a.68.68,0,0,0,.608-1.216L18.922,17.4l6.848-3.452Zm-15.207-.757L2.2,8.98,17.407,1.438,32.614,8.98Z"
          transform="translate(0)"
          fill={fill}
        />
        <path
          id="Path_54"
          data-name="Path 54"
          d="M204.435,122.072l-3.06,1.53a.68.68,0,0,0,.608,1.216l2.756-1.378,2.756,1.378a.68.68,0,1,0,.608-1.216l-3.06-1.53A.679.679,0,0,0,204.435,122.072Z"
          transform="translate(-187.332 -113.705)"
          fill={fill}
        />
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          transform="translate(25.406 30.406)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
