import styled from "@emotion/styled";

export const Console = styled.div`
  height: 3rem;
  background: #fff;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  cursor: pointer;
`;
