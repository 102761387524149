import React, { useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from "react-query";
import { IMedia, IVM } from "types/virtual";
import Button from "ui/Button";
import ErrorMsg from "ui/ErrorMsg";
import { RowItem, RowItemHeader, RowWrapper } from "ui/RadioButtonsTable";
import SelectRadio from "ui/SelectRadio";
import api from "utils/api";
import { getErrorMessage } from "utils/parser";
import { Actions, Title, Wrapper } from "./styles";
import Modal from "ui/Modal";

interface IMediaRowProps {
  media: IMedia;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const MediaRow = ({ handleChange, media }: IMediaRowProps) => (
  <RowWrapper colCount={4}>
    <SelectRadio
      type="radio"
      name="template"
      onChange={handleChange}
      value={media.href}
    />
    <RowItem>{media.name}</RowItem>
    <RowItem>{media.catalogName}</RowItem>
    <RowItem>{media.ownerName}</RowItem>
  </RowWrapper>
);

interface IProps {
  vmId: string;
  type: "ISO" | "FLOPPY";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchVMs: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IVM[], unknown>>;
}

export default function MountMediaModal({
  vmId,
  type,
  open,
  setOpen,
  refetchVMs,
}: IProps) {
  const [selectedMedia, setSelectedMedia] = useState<IMedia | null>(null);

  const { data: medias = [], ...getMediasQuery } = useQuery(
    `get-medias-${vmId}-${type}`,
    async () => {
      const { data } = await api.get("/vcloud-director/query/", {
        params: {
          type: "media",
          filter: `((isIso==${type === "ISO"}))`,
        },
      });
      return data as IMedia[];
    }
  );

  interface IInsertMediaOptions {
    vmId: string;
    media: IMedia;
  }

  const insertMediaMutation = useMutation(
    "insert-media",
    async ({ vmId, media }: IInsertMediaOptions) =>
      api.post("/vcloud-director/virtual/action/insert-media", {
        vmId,
        media,
      }),
    {
      onSuccess: () => {
        refetchVMs();
        setOpen(false);
      },
    }
  );

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    const media = medias.find((item) => checked && item.href === value);
    if (media) {
      setSelectedMedia(media);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!selectedMedia) return;
    insertMediaMutation.mutate({ vmId, media: selectedMedia });
  };

  const error = insertMediaMutation.error || getMediasQuery.error;

  return (
    <Modal open={open}>
      <Wrapper>
        {error && <ErrorMsg message={getErrorMessage(error)} />}
        <Title>Select the media file to insert in the VM</Title>
        <RowWrapper colCount={4}>
          <RowItemHeader></RowItemHeader>
          <RowItemHeader>Name</RowItemHeader>
          <RowItemHeader>Catalog</RowItemHeader>
          <RowItemHeader>Owner</RowItemHeader>
        </RowWrapper>
        {medias.map((media) => (
          <MediaRow
            key={media.href}
            media={media}
            handleChange={handleChange}
          />
        ))}
        <Actions>
          <Button color="secondary" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="outlined" onClick={handleSubmit}>
            Insert Media
          </Button>
        </Actions>
      </Wrapper>
    </Modal>
  );
}
