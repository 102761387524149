import SeparatorSvg from "assets/svg/LeftArrow";
import VAppSvg from "assets/svg/VApp";
import VMSvg from "assets/svg/VM";
import React from "react";
import { BreadcrumbsPath, BreadcrumbsText, BreadcrumbsWrapper } from "./styles";

type BreadcrumbsProps = {
    containerName: string;
    name: string;
    isAutoNature: boolean;
  };
  
  const Breadcrumbs = ({
    containerName,
    name,
    isAutoNature,
  }: BreadcrumbsProps) => {
    return (
      <BreadcrumbsWrapper>
        {isAutoNature ? (
          <BreadcrumbsPath>
            <VAppSvg fill="#fff" width={12} />
            <BreadcrumbsText>Standalone</BreadcrumbsText>
          </BreadcrumbsPath>
        ) : (
          <>
            <BreadcrumbsPath>
              <VAppSvg fill="#fff" width={12} />
              <BreadcrumbsText>{containerName}</BreadcrumbsText>
            </BreadcrumbsPath>
            <SeparatorSvg fill="#fff" width={12} />
            <BreadcrumbsPath>
              <VMSvg fill="#fff" width={12} />
              <BreadcrumbsText>{name}</BreadcrumbsText>
            </BreadcrumbsPath>
          </>
        )}
      </BreadcrumbsWrapper>
    );
  };

  export default Breadcrumbs;