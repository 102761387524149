import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const Instruction = styled.div`
  display: flex;
  margin: 0.5rem auto;
  & > strong {
    margin-right: 0.5rem;
  }
  width: 100%;
`;

export const Row = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-bottom: ${({ theme: { spacing } }) => spacing.lg};
  }
`;

export const Image = styled.img`
  height: 10rem;
  width: 10rem;
  border-radius: ${({ theme: { spacing } }) => spacing.sm};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
