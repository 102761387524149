import CryptoJS from 'crypto-js';

// eslint-disable-next-line
export const updateObject = (obj: any, path: string, val: any) => {
  const newObj = { ...obj };
  const keys = path.split(".");
  const lastKey = keys.pop();
  const lastObj = keys.reduce(
    (newObj, key) => (newObj[key] = newObj[key] || {}),
    newObj
  );
  if (lastKey) {
    lastObj[lastKey] = val;
  }
  return newObj;
};

export const decrypt = (data: string, secretKey: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch {
    return ""
  }
};
