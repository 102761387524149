import React from "react";
import { IDropDowItemProps, Item, Wrapper } from "./styles";

interface IProps {
  id: string;
  status: string;
  children?: React.ReactNode;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  minWidth?: number;
}

export default function DropDown({
  top,
  bottom,
  left,
  right,
  children,
  minWidth,
}: IProps) {
  return (
    <Wrapper
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      minWidth={minWidth}
    >
      {children}
    </Wrapper>
  );
}

export function DropDownItem({ children, ...rest }: IDropDowItemProps) {
  return <Item {...rest}>{children}</Item>;
}
