import styled from "@emotion/styled";

export interface ISnackBarProps {
  message?: string;
  type: "success" | "error";
  display?: "none" | "grid";
}

export const Wrapper = styled.div<ISnackBarProps>`
  position: fixed;
  display: ${(props) => props.display};
  grid-template-columns: 11fr 1fr;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  min-width: 400px;
  bottom: ${(props) => props.theme.spacing.xlg};
  background-color: ${(props) => props.theme.palette[props.type].main};
  padding: ${(props) => props.theme.spacing.lg};
  border-radius: 5px;
  left: 40%;
  right: 50%;
`;

interface ISnackBarCloseButton {
  color: "error" | "success";
}
export const CloseButton = styled.button<ISnackBarCloseButton>`
  background-color: ${(props) => props.theme.palette[props.color].main};
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
`;
