import React from "react";

type LeftArrowProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function LeftArrow({
  fill = "#000",
  width = 8,
  height = 8,
}: LeftArrowProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 4.989 8.793"
    >
      <g id="down-arrow" transform="translate(-117.048 8.507) rotate(-90)">
        <g id="Group_53" data-name="Group 53" transform="translate(0 117.334)">
          <path
            id="Path_93"
            data-name="Path 93"
            d="M8.121,117.434a.343.343,0,0,0-.484,0L4.11,120.96.585,117.434a.343.343,0,0,0-.484.484l3.768,3.768a.343.343,0,0,0,.484,0l3.768-3.768A.343.343,0,0,0,8.121,117.434Z"
            transform="translate(0 -117.334)"
            fill={fill}
            stroke={fill}
          />
        </g>
      </g>
    </svg>
  );
}
