import styled from "@emotion/styled";

export const BreadcrumbsWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
svg {
  margin: 0 .2rem;
}
`;

export const BreadcrumbsPath = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

export const BreadcrumbsText = styled.div``;
