import styled from "@emotion/styled";

export const Wrapper = styled.form`
  min-width: 40rem;
  padding: 4rem 2rem 2rem 2rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  button:not(:last-child) {
    margin-right: 1rem;
  }
  justify-content: flex-end;
`;
