import React from "react";
import { FaBell } from "react-icons/fa";
import Button from "ui/Button";
import { BadgeValue, BadgeWrapper } from "./styles";
interface IProps {
  value: string | number;
  color?: string;
  size?: number;
  onClick?: () => void;
}

export default function Badge({
  value,
  color = "blue",
  size = 30,
  onClick,
}: IProps) {
  return (
    <BadgeWrapper onClick={onClick}>
      <Button variant="text">
        <FaBell size={size} />
      </Button>
      {value > 0 && <BadgeValue color={color}>{value}</BadgeValue>}
    </BadgeWrapper>
  );
}
