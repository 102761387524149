import styled from "@emotion/styled";

type WrapperProps = {
  marginTop?: number;
  marginLeft?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop + "px"};
  margin-left: ${({ marginLeft }) => marginLeft + "px"};
`;
