import React, { useState } from "react";
import ReactDataTable, { IDataTableProps } from "react-data-table-component";
import { OptionTypeBase } from "react-select";

import {
  Wrapper,
  customStyles,
  TablePaginationWrapper,
  EntriesInformation,
  Pages,
  Header,
  HeaderLeft,
  SearchWrapper,
} from "./styles";
import Button from "ui/Button";
import Select from "ui/Select";
import TextField from "ui/TextField";
import { FaSearch } from "react-icons/fa";

export const paginationPageOptions = [
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

export const paginationRowsPerPage = { value: 10, label: "10" };

export const paginationDefaultOptions = {
  rangePrefixText: "Showing",
  rangeSeparatorText: "of",
  rangeSuffixText: "",
};

interface IPaginationProps {
  rowsPerPage: number;
  rowCount: number;
  onChangeRowsPerPage: (
    currentRowsPerPage: number,
    currentPage: number
  ) => void;
  onChangePage: (page: number, totalRows: number) => void;
  currentPage: number;
  paginationTopRight?: boolean;
}

interface IPageButtonProps {
  page: number | string;
  currentPage: number;
  onChangePage: () => void;
}

const PageButton = ({ page, currentPage, onChangePage }: IPageButtonProps) => (
  <Button
    disabled={currentPage == page}
    variant={currentPage == page ? "contained" : "outlined"}
    style={{ marginLeft: 5, marginRight: 5 }}
    onClick={onChangePage}
  >
    {page}
  </Button>
);

const TablePagination = ({
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  rowCount,
  rowsPerPage,
  paginationTopRight,
}: IPaginationProps) => {
  const numberOfPages =
    Math.floor(rowCount / rowsPerPage) + (rowCount % rowsPerPage == 0 ? 0 : 1);

  const isFirstPage = currentPage <= 1;
  const isLastPage = currentPage >= numberOfPages;

  const handleRowsPerPageChange = (value: OptionTypeBase | null) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeRowsPerPage((value as any).value as number, currentPage);
  };

  return (
    <TablePaginationWrapper paginationTopRight={paginationTopRight}>
      <EntriesInformation>
        {paginationDefaultOptions.rangePrefixText}{" "}
        {rowCount >= rowsPerPage ? rowsPerPage : rowCount}{" "}
        {paginationDefaultOptions.rangeSeparatorText} {rowCount}{" "}
        {paginationDefaultOptions.rangeSuffixText}
      </EntriesInformation>
      <Pages>
        <Button
          variant={isFirstPage ? "outlined" : "contained"}
          disabled={isFirstPage}
          onClick={() => onChangePage(currentPage - 1, rowCount)}
        >
          {"<"}
        </Button>

        <PageButton
          onChangePage={() => onChangePage(1, rowCount)}
          currentPage={currentPage}
          page={1}
        />

        {numberOfPages > 2 && (
          <PageButton
            onChangePage={() => onChangePage(2, rowCount)}
            currentPage={currentPage}
            page={2}
          />
        )}

        {numberOfPages > 4 && currentPage > 3 && (
          <PageButton
            onChangePage={() => onChangePage(currentPage - 5, rowCount)}
            currentPage={currentPage}
            page={"..."}
          />
        )}

        {numberOfPages > 2 &&
          new Array(numberOfPages - 2).fill(0).map((_, idx) => {
            if (idx + 1 > 2 && currentPage == idx + 1) {
              return (
                <PageButton
                  key={idx}
                  onChangePage={() => onChangePage(idx + 1, rowCount)}
                  currentPage={currentPage}
                  page={idx + 1}
                />
              );
            }
          })}

        {numberOfPages > 4 && numberOfPages - currentPage > 2 && (
          <PageButton
            onChangePage={() => onChangePage(currentPage + 5, rowCount)}
            currentPage={currentPage}
            page={"..."}
          />
        )}

        {numberOfPages > 3 && (
          <PageButton
            onChangePage={() => onChangePage(numberOfPages - 1, rowCount)}
            currentPage={currentPage}
            page={numberOfPages - 1}
          />
        )}

        {numberOfPages > 1 && (
          <PageButton
            onChangePage={() => onChangePage(numberOfPages, rowCount)}
            currentPage={currentPage}
            page={numberOfPages}
          />
        )}

        <Button
          variant={isLastPage ? "outlined" : "contained"}
          onClick={() => onChangePage(currentPage + 1, rowCount)}
          disabled={isLastPage}
        >
          {">"}
        </Button>
      </Pages>
      <Select
        onChange={handleRowsPerPageChange}
        options={paginationPageOptions}
        width="8rem"
        hasIndicatorDropDown
        hasIndicatorSeparator
        colorLabel="primary"
        defaultValue={paginationDefaultOptions}
        withError={false}
        value={{ label: rowsPerPage, value: rowsPerPage }}
      />
    </TablePaginationWrapper>
  );
};

interface ISearchProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const Search = ({ searchValue, setSearchValue }: ISearchProps) => {
  return (
    <SearchWrapper>
      <TextField
        value={searchValue}
        onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
        placeholder="Search"
        withError={false}
      />
      <FaSearch />
    </SearchWrapper>
  );
};

interface IProps<T> extends IDataTableProps<T> {
  headerChildren?: React.ReactNode;
  paginationTopRight?: boolean;
  searchField?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DataTable<T = any>({
  headerChildren,
  paginationTopRight,
  searchField,
  data,
  ...props
}: IProps<T>) {
  const [searchValue, setSearchValue] = useState("");
  const filteredItems = searchField
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.filter((one: any) => {
        return (
          typeof one[searchField] !== "string" ||
          one[searchField]
            .toLowerCase()
            .trim()
            .indexOf(searchValue.toLowerCase().trim()) !== -1
        );
      })
    : [];

  return (
    <Wrapper>
      {(paginationTopRight || headerChildren || searchField) && (
        <Header>
          <HeaderLeft>
            {searchField && (
              <Search
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {headerChildren}
          </HeaderLeft>
        </Header>
      )}
      <ReactDataTable
        customStyles={customStyles}
        noHeader
        highlightOnHover={true}
        paginationComponent={(paginationProps: IPaginationProps) => (
          <TablePagination
            {...paginationProps}
            paginationTopRight={paginationTopRight}
          />
        )}
        data={searchField ? filteredItems : data}
        {...props}
      />
    </Wrapper>
  );
}
