import styled from "@emotion/styled";

export const BadgeWrapper = styled.div`
  position: relative;
`;

interface IBadgeValue {
  color: string;
}

export const BadgeValue = styled.span<IBadgeValue>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 50%;
  color: #fff;
  background-color: ${({ color }) => color};
`;
