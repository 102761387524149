import styled from "@emotion/styled";

export const Wrapper = styled.div`
  min-height: 100vh;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
