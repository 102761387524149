import Layout from "features/tool/components/Layout";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { IDataCenter } from "types/tool";
import DataTable from "ui/DataTable";
import FabButton from "ui/FabButton";
import api from "utils/api";
import { getRecord } from "utils/parser";

const columns = [
  {
    name: "Name",
    selector: "name",
  },
  {
    name: "vCloud URL",
    selector: "vCloudURL",
  },
  {
    name: "CPU speed (GHz)",
    selector: "cpuSpeed",
  },
];

export default function DataCenters() {
  const { data: dataCenters = [], isLoading } = useQuery(
    "dataCenters",
    async () => {
      const response = await api.get("/tool/datacenters");
      return getRecord(response) as IDataCenter[];
    }
  );
  const history = useHistory();

  return (
    <Layout>
      <Link to="/tool/settings/datacenters/create">
        <FabButton>
          <FaPlus />
        </FabButton>
      </Link>
      <DataTable
        columns={columns}
        data={dataCenters}
        progressPending={isLoading}
        noHeader
        onRowClicked={(row) =>
          history.push(`/tool/settings/datacenters/update/${row._id}`)
        }
        pointerOnHover={true}
        highlightOnHover={true}
      />
    </Layout>
  );
}
