import isCidr from "is-cidr";
import { Netmask } from "netmask";
import validator from "validator";
import api from "./api";
interface IValidations {
  [key: string]: {
    validator: (
      value: string,
      min?: number,
      max?: number
    ) => boolean | Promise<boolean>;
    message: string | (() => string);
  };
}

const useVerifyUnity = async (key: string, value: string) => {
  let issUnique = false;
  const checkUnicity = async () => {
    const response = await api.post("/tool/validate", { key, value });
    issUnique = response.data.unique;
  };
  await checkUnicity();
  return issUnique;
};

const validations: IValidations = {
  "*": {
    validator: (value: string) => validator.isEmpty(value),
    message: "This field is required",
  },
  orgName: {
    validator: (value: string) => useVerifyUnity("orgName", value),
    message: "Organization must be unique !!",
  },
  orgPrefix: {
    validator: (value: string) =>
      validator.isLength(value, { min: 4, max: 8 }) &&
      validator.isAlpha(value) &&
      validator.isUppercase(value),
    message:
      "Organization prefix must be uppercased, contains only letters [A,Z] and have a length between (4,8) !",
  },
  email: {
    validator: (value: string) => validator.isEmail(value),
    message: "Email is not valid",
  },
  phone: {
    validator: (value: string) => validator.isMobilePhone(value),
    message: "Phone number is not valid",
  },
  message: {
    validator: (value: string) => validator.isLength(value, { min: 20 }),
    message: "Message should contain at least 20 caracter",
  },
  username: {
    validator: (value: string) => value.indexOf(" ") === -1,
    message: "Username should not contain white spaces",
  },
  datacenter: {
    validator: (value: string) => validator.isLength(value, { min: 10 }),
    message: "At least one data center must be selected",
  },
  dataCenter: {
    validator: (value: string) => validator.isInt(value, { min: 1 }),
    message: "At least one data center must be added",
  },
  "resources.nbOfVMs": {
    validator: (value: string, min: number = 1) => validator.isInt(value, { min }),
    message: () => 'VMs must be a valid number',
  },
  "resources.nbOfCPUs": {
    validator: (value: string, min: number = 1) => validator.isInt(value, { min }),
    message: () => 'CPUs must be greater or equal than VMs',
  },
  "resources.maxMemory": {
    validator: (value: string, min: number = 1) => validator.isInt(value, { min }),
    message: () => 'Memory must be greater or equal than VMs',
  },
  "resources.maxStorage": {
    validator: (value: string, min: number = 1) => validator.isInt(value, { min }),
    message: () => 'Storage must be greater or equal than VMs',
  },
  gateway: {
    validator: (value) => {
      if (isCidr.v4(value)) {
        const ip = value.split("/")[0];
        const block = new Netmask(value);
        return ip !== block.base && ip !== block.broadcast;
      }
      return false;
    },
    message: "Gateway must be a valid IP/CIDR",
  },
  primaryDNS: {
    validator: (value) => validator.isIP(value),
    message: "Primary DNS must be a valid IP",
  },
  secondaryDNS: {
    validator: (value) => validator.isIP(value),
    message: "Secondary DNS must be a valid IP",
  },
  ip: {
    validator: (value) => validator.isIP(value),
    message: "IP Address invalid",
  }
};

export const isValid = async (
  fieldName: string,
  fieldValue: string,
  min?: number,
  max?: number
) => {
  // default validation for all fields
  if (validations["*"].validator(fieldValue)) {
    return validations["*"].message as string;
  }

  // remove index from field name
  if (/.*\[\d+\]$/.test(fieldName)) {
    fieldName = fieldName.split("[")[0];
  }

  // custom validation per field
  if (fieldName in validations) {
    switch (fieldName) {
      // msp or orgName
      // case "msp":
      case "token":
        if (
          !validator.isNumeric(fieldValue) ||
          !validator.isLength(fieldValue, { max: 6, min: 6 })
        ) {
          return "Invalid token format !";
        }
        break;
      case "orgName":
        {
          const unique = await (validations[fieldName].validator(
            fieldValue
          ) as Promise<boolean>);
          if (!unique) {
            return validations[fieldName].message as string;
          }

          if (
            fieldName === "orgName" &&
            !validator.isURL(fieldValue, { require_protocol: false })
          ) {
            return "Invalid domain name";
          }
        }
        break;
      // resources
      case "resources.nbOfVMs":
      case "resources.nbOfCPUs":
      case "resources.maxMemory":
      case "resources.maxStorage":
        if (!validations[fieldName].validator(fieldValue, min, max)) {
          const callback = validations[fieldName].message as (
            min?: number,
            max?: number
          ) => string;
          return callback(min, max);
        }
        break;
      // anything else
      default:
        if (!validations[fieldName].validator(fieldValue)) {
          return validations[fieldName].message as string;
        }
    }
  }

  return null;
};
