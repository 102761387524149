import axios from "axios";
import socketIOClient from "socket.io-client";
const socket = socketIOClient();

const PREFIX_API = "/api/v1";

const api = axios.create({
  baseURL: PREFIX_API,
});

export const on = (event = "message", cb: (data: string) => void) => {
  socket.on(event, (data: string) => {
    cb(data);
  });
};

export const emit = (event = "message", mesage: string) => {
  socket.emit(event, mesage);
};

socket.on("close", () => {
  socket.close();
});

export default api;
