import styled, { CSSObject } from "@emotion/styled";

type WrapperProps = {
  width?: string;
  isCenter?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
 width: ${({ width }) => (width ? width : "100%")};
 ${({ isCenter }) => isCenter && `
  margin: auto;
 `}
`;

export const customStyles = (hasIndicatorSeparator: boolean) => (hasIndicatorSeparator ? {
  control: (base: CSSObject) => ({
    ...base,
    border: "0px",
    boxShadow: "0px -1px 9px 0px #e0e0e0",
  }),
  indicatorSeparator: (base: CSSObject) => ({
    ...base,
    display: "none",
  }),
  singleValue: (base: CSSObject) => ({
    ...base,
    color: "#737775",
    fontSize: "1.5rem"
  }),
  option: (base: CSSObject) => ({
    ...base,
    color: "#737775",
  }),
} : {
  container: (base: CSSObject) => ({
    ...base,
    marginBottom: "1rem",
  }),
})

export const ErrorMsg = styled.div`
  color: ${(props) => props.theme.palette.error.main};
  font-style: italic;
  font-size: 1.2rem;
  min-height: 2rem;
`;
