import React, { useState } from "react";

import { useCustomer } from "providers/customer";
import { Menu, MspInfos, MspInfosItem, Wrapper } from "./styles";
import Notifications from "features/dashboard/components/Notifications";

export default function Navbar() {
  const [openNotification, setOpenNotification] = useState(false);
  const { customer } = useCustomer();

  return (
    <Wrapper>
      <MspInfos>
        <MspInfosItem>
          <span>Partner Msp: </span>
          {customer?.msp}
        </MspInfosItem>
        <MspInfosItem>
          <span>Datacenter status: </span>
          {customer?.resources.map((one) => one.dataCenter).join(" ")}
        </MspInfosItem>
      </MspInfos>
      <Menu>
        <Notifications open={openNotification} setOpen={setOpenNotification} />
      </Menu>
    </Wrapper>
  );
}
