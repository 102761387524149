import React from "react";
import { IKeyValue } from "types/common";
import { IDataCenterForm } from "types/tool";
import TextField from "ui/TextField";
import { ActionsWrapper, Form } from "./styles";

interface IProps {
  form: IDataCenterForm;
  children: React.ReactNode;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleBlur: (e: React.FormEvent<HTMLInputElement>) => void;
  handleFocus: (e: React.FormEvent<HTMLInputElement>) => void;
  errors: IKeyValue;
  touched: IKeyValue<boolean>;
}

export default function DataCenterForm({
  form,
  children,
  handleChange,
  handleSubmit,
  handleBlur,
  handleFocus,
  errors,
  touched,
}: IProps) {
  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        name="name"
        value={form.name}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={errors["name"]}
        touched={touched["name"]}
        required
      />
      <TextField
        label="vCloud URL"
        name="vCloudURL"
        value={form.vCloudURL}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={errors["vCloudURL"]}
        touched={touched["vCloudURL"]}
        required
      />
      <TextField
        label="CPU speed (GHz)"
        name="cpuSpeed"
        value={form.cpuSpeed}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={errors["cpuSpeed"]}
        touched={touched["cpuSpeed"]}
        required
        type="number"
      />

      <ActionsWrapper>{children}</ActionsWrapper>
    </Form>
  );
}
