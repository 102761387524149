import styled from "@emotion/styled";

export const Wrapper = styled.div``;

export const NotificationHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NotificationItem = styled.div`
  color: #000;
  font-size: 1.5rem;
`;

export const NotificationDate = styled.span`
color: ${(props) => props.theme.palette.primary.dark};
font-size: 1.5rem;
margin-right: 0.5rem;
`;
