import styled from "@emotion/styled";

export const Wrapper = styled.div`
  min-width: 50rem;
`;

export const RowsWrapper = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.04);
  padding: 1.5rem;
  font-size: 1.4rem;
  margin: 1rem;
`;

export const Label = styled.label``;

export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  padding: 2rem;
  button:not(:last-child) {
    margin-right: 2rem;
  }
`;
