import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { NotificationForm } from "features/tool/components/NotificationForm";
import { useMutation, useQuery } from "react-query";
import { INotification } from "types/notifications";
import Button from "ui/Button";
import DataTable from "ui/DataTable";
import FabButton from "ui/FabButton";
import api from "utils/api";
import { ActionsWrapper, Title, Wrapper } from "./styles";
import { IDataCenter } from "types/tool";
import Select from "ui/Select";
import { ActionMeta, OptionTypeBase } from "react-select";
import Toggle from "ui/Toggle";
import TextArea from "ui/TextArea";

export default function Notifications() {
  const [openForm, setOpenForm] = useState(false);

  const { data: dataCenters = [] } = useQuery("data-centers", async () => {
    const { data } = await api.get("/tool/datacenters");
    return data as IDataCenter[];
  });

  const { data: notifications = [], refetch } = useQuery(
    `notifications-${dataCenters}`,
    async () => {
      const { data } = await api.get("/tool/notifications");
      return data as INotification[];
    }
  );

  const updateMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (notification: any) => api.put(`/tool/notifications`, notification),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const deleteMutation = useMutation(
    async (id: string) => api.delete(`/tool/notifications`, { data: { id } }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleSelectChange = (
    option: OptionTypeBase | null,
    { name: n }: ActionMeta<OptionTypeBase>
  ) => {
    if (!option) return;
    const [name, id] = n?.split("-") || [];
    const notification = notifications.find((one) => one._id === id);
    if (notification) {
      const newNotification = { ...notification, [name]: option.value };
      updateMutation.mutate(newNotification);
    }
  };

  const handleMessageChange = ({
    currentTarget: { value, name: n },
  }: React.FocusEvent<HTMLTextAreaElement>) => {
    const [name, id] = n.split("-") || [];
    const notification = notifications.find((one) => one._id === id);
    if (notification) {
      const newNotification = { ...notification, [name]: value };
      updateMutation.mutate(newNotification);
    }
  };

  const handleIsPublished = (id: string) => {
    const notification = notifications.find((one) => one._id === id);
    if (notification) {
      const newNotification = {
        ...notification,
        isPublished: !notification.isPublished,
      };
      updateMutation.mutate(newNotification);
    }
  };

  const columns = [
    {
      name: "Data center",
      cell: function renderCell(row: INotification) {
        return (
          <Select
            onChange={handleSelectChange}
            name={`dataCenter-${row._id}`}
            label="Data center"
            options={dataCenters.map((one) => ({
              label: one.name,
              value: one._id,
            }))}
            value={{
              label: row.dataCenter.name,
              value: row.dataCenter._id,
            }}
            placeholder="Select datacenter"
            required
          />
        );
      },
    },
    {
      name: "Notification",
      compact: true,
      cell: function renderCell(row: INotification) {
        return (
          <TextArea
            name={`message-${row._id}`}
            defaultValue={row.message}
            onBlur={handleMessageChange}
          />
        );
      },
    },
    {
      name: "Publish",
      compact: true,
      cell: function renderCell(row: INotification) {
        return (
          <Toggle
            onClick={() => handleIsPublished(row._id || "")}
            isOn={row.isPublished}
          />
        );
      },
    },
    {
      name: "Action",
      compact: true,
      cell: function renderCell(row: INotification) {
        return (
          <ActionsWrapper>
            <Button
              variant="text"
              onClick={() => deleteMutation.mutate(row._id || "")}
            >
              <>
                <FaTrash />
                Delete
              </>
            </Button>
          </ActionsWrapper>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Title>Notifications </Title>
      <DataTable
        columns={columns}
        data={notifications}
        noDataComponent={<Title>No notifications</Title>}
        noHeader
      />
      <NotificationForm
        open={openForm}
        setOpen={setOpenForm}
        refetch={refetch}
        dataCenters={dataCenters}
      />
      <FabButton onClick={() => setOpenForm(true)}>
        <FaPlus />
      </FabButton>
    </Wrapper>
  );
}
