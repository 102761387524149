
export default {
  palette: {
    primary: {
      light: '#5F82CF',
      main: '#3e66b0',
      dark: '#09478D'
    },
    secondary: {
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c'
    },
    danger: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    },
    gray: {
      main: "#777"
    },
    divider: '#e0e0e0'
  },
  spacing: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xlg: '32px',
  },
}
