import React from "react";

type CPUProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function CPU({
  fill = "#000",
  width = 20,
  height = 20,
}: CPUProps) {
  return (
    <svg
      id="memory"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.966 19.966"
    >
      <path
        id="Path_95"
        data-name="Path 95"
        d="M19.381,9.008a.585.585,0,0,0,0-1.17H16.808V5.889h2.574a.585.585,0,0,0,0-1.17H16.808v-.39a1.171,1.171,0,0,0-1.17-1.17h-.39V.585a.585.585,0,1,0-1.17,0V3.159h-1.95V.585a.585.585,0,1,0-1.17,0V3.159H9.008V.585a.585.585,0,0,0-1.17,0V3.159H5.889V.585a.585.585,0,0,0-1.17,0V3.159h-.39a1.171,1.171,0,0,0-1.17,1.17v.39H.585a.585.585,0,0,0,0,1.17H3.159v1.95H.585a.585.585,0,0,0,0,1.17H3.159v1.95H.585a.585.585,0,1,0,0,1.17H3.159v1.95H.585a.585.585,0,1,0,0,1.17H3.159v.39a1.171,1.171,0,0,0,1.17,1.17h.39v2.574a.585.585,0,0,0,1.17,0V16.808h1.95v2.574a.585.585,0,0,0,1.17,0V16.808h1.95v2.574a.585.585,0,0,0,1.17,0V16.808h1.95v2.574a.585.585,0,0,0,1.17,0V16.808h.39a1.171,1.171,0,0,0,1.17-1.17v-.39h2.574a.585.585,0,0,0,0-1.17H16.808v-1.95h2.574a.585.585,0,0,0,0-1.17H16.808V9.008Zm-3.744,6.629H4.329V4.329H15.638C15.638,15.952,15.642,15.638,15.638,15.638Z"
        fill={fill}
      />
      <path
        id="Path_96"
        data-name="Path 96"
        d="M167.068,161.171a.585.585,0,0,0-.414-.171h-3.9a.585.585,0,0,0-.414.171l-1.17,1.17a.585.585,0,0,0-.171.414v3.9a.585.585,0,0,0,.171.414l1.17,1.17a.585.585,0,0,0,.414.171h3.9a.585.585,0,0,0,.414-.171l1.17-1.17a.585.585,0,0,0,.171-.414v-3.9a.585.585,0,0,0-.171-.414Zm.171,5.241-.827.827H163l-.827-.827V163l.827-.827h3.415l.827.827Z"
        transform="translate(-154.722 -154.722)"
        fill={fill}
      />
    </svg>
  );
}
