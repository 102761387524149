import React, { InputHTMLAttributes } from "react";
import Label from "ui/Label";
import { ErrorMsg, Input, Wrapper } from "./styles";

interface ITextField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  touched?: boolean;
  withError?: boolean;
}

export default function TextField({
  label,
  touched,
  error,
  withError = true,
  type = "text",
  width,
  ...rest
}: ITextField) {
  return (
    <Wrapper width={width}>
      {label && <Label>{label}</Label>}
      <Input type={type} {...rest} />
      {withError && <ErrorMsg>{touched && error}</ErrorMsg>}
    </Wrapper>
  );
}
