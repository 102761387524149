import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CloseButton, ISnackBarProps, Wrapper } from "./styles";

const SnackBar = (props: ISnackBarProps) => {
  const [display, setDisplay] = React.useState<"none" | "grid">("grid");
  const handleClose = () => setDisplay("none");
  React.useEffect(() => {
    setTimeout(handleClose, 5000);
  }, []);
  return (
    <Wrapper type={props.type} display={display}>
      {props.message}
      <CloseButton color={props.type} onClick={handleClose}>
        <AiOutlineClose />
      </CloseButton>
    </Wrapper>
  );
};
export default SnackBar;
