import VMs from "features/virtual/modules/VMs";
import React from "react";
import Layout from "ui/Layout";


export default function VMsPage() {
  return (
    <Layout isMsp>
      <VMs />
    </Layout>
  );
}
