import React from 'react';
import UpdateDataCenter from 'features/tool/modules/UpdateDataCenter';
import { UserProvider } from 'providers/user';

export default function ToolUpdateDataCenterPage() {
  return (
    <UserProvider>
      <UpdateDataCenter />
    </UserProvider>
  );
}
