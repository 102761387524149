import React, { useState } from "react";
import { QueryObserverResult, RefetchOptions, useMutation } from "react-query";
import { ActionMeta, OptionTypeBase } from "react-select";

import { INotification } from "types/notifications";
import { IDataCenter } from "types/tool";
import Button from "ui/Button";
import Modal from "ui/Modal";
import Select from "ui/Select";
import TextArea from "ui/TextArea";
import Toggle from "ui/Toggle";
import api from "utils/api";
import { Form, ActionsWrapper, Fields, Title } from "./styles";

interface IProps {
  open: boolean;
  dataCenters: IDataCenter[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<INotification[], unknown>>;
}

export const NotificationForm = ({
  open,
  dataCenters,
  setOpen,
  refetch,
}: IProps) => {
  const [form, setForm] = useState({
    dataCenter: "",
    message: "",
    isPublished: false,
  });

  const mutation = useMutation(
    async () => api.post(`/tool/notifications`, form),
    {
      onSuccess: () => {
        setOpen(false);
        refetch();
      },
    }
  );

  const handleSelectChange = (
    option: OptionTypeBase | null,
    { name }: ActionMeta<OptionTypeBase>
  ) => {
    if (!option) return;
    option.value && name && setForm({ ...form, [name]: option.value });
  };

  const handleChange = ({
    currentTarget: { value, name },
  }: React.FormEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} closeOnDocumentClick>
      <Form onSubmit={handleSubmit}>
        <Title>Add notification</Title>
        <Fields>
          <Select
            onChange={handleSelectChange}
            name="dataCenter"
            label="Data center"
            options={dataCenters.map((one) => ({
              label: one.name,
              value: one._id,
            }))}
            value={{
              label:
                dataCenters.find((one) => one._id === form.dataCenter)?.name ||
                "",
              value: form.dataCenter,
            }}
            placeholder="Select datacenter"
            required
          />
          <TextArea
            placeholder="Notification"
            name="message"
            value={form.message}
            autoComplete="off"
            required={true}
            onChange={handleChange}
          />
          <Toggle
            label="Publish"
            isOn={form.isPublished}
            onClick={() => setForm({ ...form, isPublished: !form.isPublished })}
          />
        </Fields>
        <ActionsWrapper>
          <Button type="submit">Save</Button>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </ActionsWrapper>
      </Form>
    </Modal>
  );
};
