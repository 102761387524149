import styled from "@emotion/styled";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 44rem;
  align-items: stretch;
  padding-right: ${(props) => props.theme.spacing.xlg};
  padding-left: ${(props) => props.theme.spacing.xlg};
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 2rem;
`;

export const Fields = styled.div``

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  & > button {
    margin: 0.5rem;
  }
`;
