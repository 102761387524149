import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
interface IInput extends HTMLAttributes<HTMLInputElement> {
  sz: "sm" | "md" | "lg";
}

export const Input = styled.input<IInput>`
  ${(props) => {
    switch (props.sz) {
      case "sm":
        return `
          width: 16px;
          height: 16px;
        `;

      case "md":
        return `
          width: 24px;
          height: 24px;
        `;
      case "lg":
        return `
          width: 32px;
          height: 32px;
        `;
    }
  }}
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.palette.gray.main};
`;
