import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaTrash, FaPlus, FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";

import Modal from "ui/Modal";
import api from "utils/api";
import {
  ActionsWrapper,
  HardDiskFormWrapper,
  SpinnerWrapper,
  Title,
  Wrapper,
} from "./styles";
import { IHardDisk, IHardDiskForm, IVM } from "types/virtual";
import Button from "ui/Button";
import Spinner from "ui/Spinner";
import TextField from "ui/TextField";
import { createHardDiskPayload } from "./helper";
import SnackBar from "ui/SnackBar";
import { getErrorMessage } from "utils/parser";

interface IHardDiskAction {
  type: "create" | "update" | null;
  hardDisk?: IHardDisk;
}

interface IHardDiskFormProps {
  form: IHardDiskForm;
  action: IHardDiskAction;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleCreate: () => void;
  handleUpdate: () => void;
  handleCancel: () => void;
}

const HardDiskForm = ({
  form,
  action,
  handleChange,
  handleCreate,
  handleUpdate,
  handleCancel,
}: IHardDiskFormProps) => {
  return (
    <HardDiskFormWrapper>
      <TextField
        type="number"
        label="Size (GB)"
        width="20rem"
        name="size"
        value={form.size}
        onChange={handleChange}
      />
      <ActionsWrapper>
        <Button
          variant="outlined"
          onClick={action.type === "create" ? handleCreate : handleUpdate}
        >
          {action.type === "create" ? "Create" : "Update"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </ActionsWrapper>
    </HardDiskFormWrapper>
  );
};

interface IProps {
  vm: IVM;
  hardDisks: IHardDisk[];
  isLoading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HardDisksModal({
  vm,
  hardDisks,
  isLoading,
  open,
  setOpen,
}: IProps) {
  const initForm = { size: 0 };
  const initAction = { type: null };

  const [form, setForm] = useState<IHardDiskForm>(initForm);
  const [action, setAction] = useState<IHardDiskAction>(initAction);

  const mutation = useMutation(
    (hardDisks: IHardDisk[]) => {
      return api.post(`/vcloud-director/virtual/vms/${vm.id}/hard-disks`, {
        payload: hardDisks,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        setAction(initAction);
        setForm(initForm);
      },
    }
  );

  const handleRemoveHardDisk = (diskId: string) => {
    const filteredHardDisks = hardDisks.filter((one) => one.diskId !== diskId);
    mutation.mutate(filteredHardDisks);
  };

  const handleCreateHardDisk = () => {
    if (hardDisks.length === 0) {
      const payload = [
        createHardDiskPayload({
          size: form.size,
          unitNumber: 0,
          vdcStorageProfileId: vm.vdcStorageProfileId,
        }),
      ];
      mutation.mutate(payload);
    } else {
      const unitNumber = hardDisks[hardDisks.length - 1].unitNumber + 1;
      const payload = [
        ...hardDisks,
        createHardDiskPayload({
          size: form.size,
          unitNumber,
          vdcStorageProfileId: vm.vdcStorageProfileId,
        }),
      ];
      mutation.mutate(payload);
    }
  };

  const handleUpdateHardDisk = () => {
    const updatedHardDisks = hardDisks.map((one) => {
      if (one.diskId === action.hardDisk?.diskId) {
        return { ...one, sizeMb: form.size * 1024 };
      }
      return one;
    });
    mutation.mutate(updatedHardDisks);
  };

  const handleHardDiskChange = ({
    currentTarget: { value, name },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCancelHardDisk = () => {
    setAction(initAction);
    setForm(initForm);
  };

  useEffect(() => {
    if (action.type === "update" && action.hardDisk?.sizeMb) {
      setForm({ size: action.hardDisk.sizeMb / 1024 });
    }
  }, [action, setForm]);

  const columns = [
    {
      name: "Name",
      selector: "storageProfile.name",
    },
    {
      name: "Size",
      cell: function Size(row: IHardDisk) {
        return <div>{row.sizeMb / 1024} GB</div>;
      },
    },
    {
      name: "Actions",
      center: true,
      cell: function Actions(row: IHardDisk) {
        return (
          <>
            <Button
              variant="text"
              onClick={() => setAction({ type: "update", hardDisk: row })}
            >
              <FaEdit />
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleRemoveHardDisk(row.diskId)}
            >
              <FaTrash />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
        <Wrapper>
          <Title>Manage hard disks ({vm.name})</Title>
          {isLoading ? (
            <Spinner height={30} />
          ) : (
            <>
              {!action.type && (
                <>
                  <DataTable columns={columns} data={hardDisks} noHeader />
                  <Button
                    variant="outlined"
                    onClick={() => setAction({ type: "create" })}
                    style={{ marginTop: 10 }}
                  >
                    <FaPlus size={12} />
                  </Button>
                </>
              )}
              {action.type && (
                <HardDiskForm
                  form={form}
                  action={action}
                  handleChange={handleHardDiskChange}
                  handleCreate={handleCreateHardDisk}
                  handleUpdate={handleUpdateHardDisk}
                  handleCancel={handleCancelHardDisk}
                />
              )}
            </>
          )}
          <SpinnerWrapper>
            {mutation.isLoading && <Spinner height={30} />}
          </SpinnerWrapper>
        </Wrapper>
      </Modal>
      {mutation.isError && (
        <SnackBar message={getErrorMessage(mutation.error)} type="error" />
      )}
    </>
  );
}
