import React from "react";
import { Wrapper } from "./styles";

interface IProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function FabButton({ onClick, children }: IProps) {
  return <Wrapper onClick={onClick}>{children}</Wrapper>;
}
