import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
interface IWrapperProps {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  minWidth?: number;
}
export const Wrapper = styled.div<IWrapperProps>`
  ${(props) => {
    let res = "";
    if (props.top) {
      res += `top: ${props.top}px;`;
    }
    if (props.bottom) {
      res += `bottom: ${props.bottom}px;`;
    }
    if (props.right) {
      res += `right: ${props.right}px;`;
    }
    if (props.left) {
      res += `left: ${props.left}px;`;
    }
    if (props.minWidth) {
      res += `min-width: ${props.minWidth}rem;`;
    }
    return res;
  }}
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: ${({ theme: { palette } }) => palette.primary.main};
  font-size: 11.2px;
  background-color: #fff;
  padding: ${({ theme: { spacing } }) => spacing.md};
  border: 1px solid ${({ theme: { palette } }) => palette.divider};
  border-radius: 10px;
  box-shadow: 1px 1px 10px -3px ${({ theme: { palette } }) => palette.divider};
  margin-top: 1rem;
  margin-left: -90px;
  cursor: default;
  z-index: 5;
  & > button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export interface IDropDowItemProps extends HTMLAttributes<HTMLDivElement> {
  isDisabled?: boolean;
}

export const Item = styled.div<IDropDowItemProps>`
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem;
  cursor: pointer;
  min-width: 90px;
  svg {
    margin-right: 1rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.divider};
    border-radius: 5px;
  }
  ${({ isDisabled, theme }) => isDisabled && `
    pointer-events: none;
    color: ${theme.palette.gray.main};
  `};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;
