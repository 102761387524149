import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  font-size: 1.4rem;
`;

type ItemProps = {
  isActive: boolean;
}

export const Item = styled.div<ItemProps>`
  &:not(:last-child) {
    margin-right: 2rem;
  }
    font-weight: bold;
  ${({ theme, isActive }) => isActive && `
    color: ${theme.palette.primary.main};
  `};
`;
