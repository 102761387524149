import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import { ICustomer, ICustomerResource } from "types/tool";
import {
  CellRows,
  CellRow,
  IsUploadedWrapper,
  IsExpiredWrapper,
  Wrapper,
  Title,
} from "./styles";

import DataTable from "ui/DataTable";
import { useCustomer } from "providers/customer";

const isUploaded = (row: ICustomer) => (
  <IsUploadedWrapper isActive={row.isUploaded}>
    {row.isUploaded ? <FaCheckCircle /> : <FaTimesCircle />}
  </IsUploadedWrapper>
);

const isExpired = (row: ICustomer) => {
  const isExpired = Date.parse(row.expirationDate) < Date.now();
  return (
    <IsExpiredWrapper isExpired={isExpired}>
      <span>{isExpired ? "Expired" : "Valid"}</span>
    </IsExpiredWrapper>
  );
};

export default function CustomerResourcesInfo() {
  const defaultCellRender = (
    row: ICustomer,
    value: React.ReactNode,
    shouldHandleClick = true
  ) => {
    return (
      <CellRows shouldHandleClick={shouldHandleClick}>
        {row.resources.map((one, index) => (
          <CellRow key={one.dataCenter}>{!index && value}</CellRow>
        ))}
        <CellRow />
      </CellRows>
    );
  };

  const resourcesCellRender = (
    row: ICustomer,
    key: keyof ICustomerResource,
    shouldHandleClick = true
  ) => {
    return (
      <CellRows shouldHandleClick={shouldHandleClick}>
        <CellRow>
          {key !== "dataCenter"
            ? row.resources.reduce((acc, cur) => acc + Number(cur[key]), 0)
            : "Total"}
        </CellRow>
        {row.resources.map((one) => (
          <CellRow key={one.dataCenter}>{one[key]}</CellRow>
        ))}
      </CellRows>
    );
  };

  const columns = [
    {
      name: "Organization",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.orgName),
    },
    {
      name: "MSP name",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.msp),
    },
    {
      name: "Default Data center",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.defaultDataCenter),
    },
    {
      name: "Data center",
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "dataCenter"),
    },
    {
      name: "Number of VMs",
      center: true,
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "nbOfVMs"),
    },
    {
      name: "Number of CPUs",
      center: true,
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "nbOfCPUs"),
    },
    {
      name: "Max Memory (GB)",
      center: true,
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "maxMemory"),
    },
    {
      name: "Max Storage (GB)",
      center: true,
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "maxStorage"),
    },
    {
      name: "Expiration Date",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.expirationDate),
    },
    {
      name: "Activated",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, isUploaded(row)),
    },
    {
      name: "Expiration status",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, isExpired(row)),
    },
  ];

  const { customer } = useCustomer();

  return (
    <Wrapper>
      <Title>Available resources allocation</Title>
      {customer && <DataTable columns={columns} data={[customer]} noHeader />}
    </Wrapper>
  );
}
