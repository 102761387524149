import React from "react";

type RAMProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function RAM({
  fill = "#000",
  width = 20,
  height = 20,
}: RAMProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.626 9.428"
    >
      <g id="ram" transform="translate(0 -140)">
        <g id="Group_76" data-name="Group 76" transform="translate(0 140)">
          <g id="Group_75" data-name="Group 75">
            <path
              id="Path_101"
              data-name="Path 101"
              d="M22.249,142.263a.377.377,0,0,0,.377-.377v-1.508a.377.377,0,0,0-.377-.377H.377a.377.377,0,0,0-.377.377v1.508a.377.377,0,0,0,.377.377.377.377,0,1,1,0,.754.377.377,0,0,0-.377.377v4.525a.377.377,0,0,0,.377.377H.754v.754a.377.377,0,0,0,.377.377H7.919a.377.377,0,0,0,.377-.377V148.3H9.05v.754a.377.377,0,0,0,.377.377H21.495a.377.377,0,0,0,.377-.377V148.3h.377a.377.377,0,0,0,.377-.377v-4.525a.377.377,0,0,0-.377-.377.377.377,0,1,1,0-.754ZM7.542,148.673H1.508V148.3H7.542Zm13.576,0H9.8V148.3H21.118Zm.754-7.1a1.131,1.131,0,0,0,0,2.134v3.835H.754v-3.835l.038-.016c.021-.008.041-.018.061-.028a1.145,1.145,0,0,0,.121-.066c.018-.011.038-.023.055-.035a1.145,1.145,0,0,0,.126-.1l.026-.023a1.159,1.159,0,0,0,.126-.151c.01-.015.019-.032.029-.047a1.124,1.124,0,0,0,.069-.128c.009-.021.018-.041.026-.063a1.053,1.053,0,0,0,.042-.136c.005-.021.011-.041.015-.062a1.033,1.033,0,0,0,0-.415c0-.021-.01-.041-.015-.062a1.052,1.052,0,0,0-.042-.136c-.008-.021-.017-.043-.026-.063a1.16,1.16,0,0,0-.069-.127c-.01-.015-.018-.032-.029-.047a1.157,1.157,0,0,0-.126-.151l-.026-.023a1.147,1.147,0,0,0-.126-.1c-.018-.013-.038-.024-.055-.035a1.14,1.14,0,0,0-.121-.066c-.02-.009-.04-.019-.061-.028s-.024-.012-.038-.016v-.822H21.872Z"
              transform="translate(0 -140)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_78"
          data-name="Group 78"
          transform="translate(9.805 141.508)"
        >
          <g id="Group_77" data-name="Group 77">
            <path
              id="Path_102"
              data-name="Path 102"
              d="M210.64,172h-2.263a.377.377,0,0,0-.377.377v3.771a.377.377,0,0,0,.377.377h2.263a.377.377,0,0,0,.377-.377v-3.771A.377.377,0,0,0,210.64,172Zm-.377,3.771h-1.508v-3.017h1.508Z"
              transform="translate(-208 -172)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_80"
          data-name="Group 80"
          transform="translate(13.576 141.508)"
        >
          <g id="Group_79" data-name="Group 79">
            <path
              id="Path_103"
              data-name="Path 103"
              d="M290.64,172h-2.263a.377.377,0,0,0-.377.377v3.771a.377.377,0,0,0,.377.377h2.263a.377.377,0,0,0,.377-.377v-3.771A.377.377,0,0,0,290.64,172Zm-.377,3.771h-1.508v-3.017h1.508Z"
              transform="translate(-288 -172)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_82"
          data-name="Group 82"
          transform="translate(2.263 141.508)"
        >
          <g id="Group_81" data-name="Group 81">
            <path
              id="Path_104"
              data-name="Path 104"
              d="M50.64,172H48.377a.377.377,0,0,0-.377.377v3.771a.377.377,0,0,0,.377.377H50.64a.377.377,0,0,0,.377-.377v-3.771A.377.377,0,0,0,50.64,172Zm-.377,3.771H48.754v-3.017h1.508Z"
              transform="translate(-48 -172)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_84"
          data-name="Group 84"
          transform="translate(6.034 141.508)"
        >
          <g id="Group_83" data-name="Group 83">
            <path
              id="Path_105"
              data-name="Path 105"
              d="M130.64,172h-2.263a.377.377,0,0,0-.377.377v3.771a.377.377,0,0,0,.377.377h2.263a.377.377,0,0,0,.377-.377v-3.771A.377.377,0,0,0,130.64,172Zm-.377,3.771h-1.508v-3.017h1.508Z"
              transform="translate(-128 -172)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_86"
          data-name="Group 86"
          transform="translate(17.347 141.508)"
        >
          <g id="Group_85" data-name="Group 85">
            <path
              id="Path_106"
              data-name="Path 106"
              d="M370.64,172h-2.263a.377.377,0,0,0-.377.377v3.771a.377.377,0,0,0,.377.377h2.263a.377.377,0,0,0,.377-.377v-3.771A.377.377,0,0,0,370.64,172Zm-.377,3.771h-1.508v-3.017h1.508Z"
              transform="translate(-368 -172)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
