import CustomerForm from "features/tool/components/CustomerForm";
import Layout from "features/tool/components/Layout";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { ICustomer, ICustomerForm } from "types/tool";
import Button from "ui/Button";
import api from "utils/api";
import { getRecord } from "utils/parser";
import { Wrapper } from "./styles";

export default function UpdateDataCenter() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const initForm: ICustomerForm = {
    customerID: "",
    msp: "",
    address: "",
    phone: "",
    orgName: "",
    orgPrefix: "",
    expirationDate: "",
    username: "",
    password: "",
    email: "",
    defaultDataCenter: "",
    resources: [],
    isUploaded: false,
    isActive: false,
  };

  const [form, setForm] = useState<ICustomerForm>(initForm);

  const { data: customer } = useQuery("fetchCustomer", async () => {
    const response = await api.get(`/tool/customers/${id}`);
    return getRecord(response) as ICustomer;
  });

  useEffect(() => {
    if (customer) {
      setForm({ ...customer });
    }
  }, [customer]);

  const updateMutation = useMutation(
    async () => {
      return api.put(`/tool/customers/${id}`, form);
    },
    {
      onSuccess: () => {
        history.push("/tool/customers/update");
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateMutation.mutate();
  };

  return (
    <Layout>
      <Wrapper>
        <CustomerForm
          initForm={initForm}
          form={form}
          setForm={setForm}
          handleSubmit={handleSubmit}
          isUpdate={true}
        >
          <Button type="submit" color="secondary">
            <FaArrowLeft /> Cancel
          </Button>
          <Button type="submit">
            <FaSave /> Update
          </Button>
        </CustomerForm>
      </Wrapper>
    </Layout>
  );
}
