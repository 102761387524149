import { useTheme } from "@emotion/react";
import React from "react";
import { FaSortDown } from "react-icons/fa";
import ReactSelect, { components, OptionTypeBase, FocusEventHandler, Props } from "react-select";
import Label from "ui/Label";
import { customStyles, Wrapper } from "./styles";
import { ErrorMsg } from "./styles";

interface IProps extends Props<OptionTypeBase> {
  label?: string;
  width?: string;
  isCenter?: boolean;
  hasIndicatorSeparator?: boolean;
  hasIndicatorDropDown?: boolean;
  colorLabel?: "primary" | "secondary";
  error?: string;
  touched?: boolean;
  withError?: boolean;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
}

const DropdownIndicator = (
  props: React.ComponentPropsWithRef<typeof components.DropdownIndicator>
) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <components.DropdownIndicator {...props}>
      <FaSortDown color={main} size={18} style={{ marginTop: "-6px" }} />
    </components.DropdownIndicator>
  );
};

export default function Select({
  width,
  label,
  touched,
  error,
  isCenter,
  colorLabel = "primary",
  hasIndicatorDropDown = false,
  hasIndicatorSeparator = false,
  withError = true,
  ...rest
}: IProps) {
  return (
    <Wrapper width={width} isCenter={isCenter}>
      {label && <Label color={colorLabel}>{label}</Label>}
      <ReactSelect
        styles={{
          ...customStyles(hasIndicatorSeparator),
        }}
        components={{
          ...(hasIndicatorDropDown && { DropdownIndicator }),
        }}
        {...rest}
      />
      {withError && <ErrorMsg>{touched && error}</ErrorMsg>}
    </Wrapper>
  );
}
