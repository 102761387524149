import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  padding: 3rem;
  margin-bottom: 3rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
`;

export const LogoWrapper = styled.div`
  margin: 2rem;
  text-align: center;
`;
