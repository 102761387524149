import React from 'react';
import { Line } from 'react-chartjs-2';
import { VscRefresh } from 'react-icons/vsc';
import data from './data';
import { FloatingButton, Title, Wrapper } from './styles';

interface IProps {
  title: string;
}

export default function LineChart({ title }: IProps) {
  const options = {
    responsive: true,
    radius: 0,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 11,
          },
        },
      },
    },
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <FloatingButton>
        <VscRefresh />
      </FloatingButton>
      <Line height={200} data={data} options={options} />
    </Wrapper>
  );
}
