import styled from "@emotion/styled";

type WrapperProps = {
  status: string;
  isBusy: boolean;
  children?: React.ReactNode;
};

const wrapperColor = (isBusy: boolean, status: string) => {
  if (isBusy) return "#FF9966"
  switch (status) {
    case "POWERED_ON": return "#26eb9f"
    case "POWERED_OFF": return "#f44336"
    default: return "#bdbdbd"
  }
}

export const Wrapper = styled.div<WrapperProps>`
  color: #fff;
  font-size: 1rem;
  padding: 4px;
  border-radius: 5px;
  min-width: 8rem;
  text-align: center;
  ${({ isBusy, status }) => `
    background-color: ${wrapperColor(isBusy, status)};
  `};
`;
