import { ICustomer } from 'types/tool';
import { IVDC, IOperatingSystem, IVMsTemplate } from 'types/virtual';
import { IVMForm } from "types/virtual";
import { plans } from "../PlanCard/config";

interface IGetVMNewParamsFromForm {
  form: IVMForm,
  vDCs: IVDC[],
  operatingSystems: IOperatingSystem[],
  vmsTemplates: IVMsTemplate[];
}

export const getVMNewParamsFromForm = ({ form, vDCs, operatingSystems, vmsTemplates }: IGetVMNewParamsFromForm) => {
  const vDCId = vDCs.find(vDC => vDC.name === form.vDC)?.id;
  const plan = plans.find(plan => plan.type === form.plan);
  const operatingSystem = operatingSystems.find(os => os.os === form.operatingSystem);
  const template = vmsTemplates.find(vmTemplate => vmTemplate.id === form.template);

  if (vDCId && plan && operatingSystem) {
    return {
      fromTemplate: form.fromTemplate,
      powerOn: form.powerOn,
      vDCId,
      name: form.name,
      hostname: form.hostname,
      description: form.description,
      cpu: plan.cpu,
      cores: plan.cores,
      memory: plan.memory,
      storage: plan.storage * 1024,
      osInternal: operatingSystem.osInternal,
      nic: operatingSystem?.nic,
    }
  } else if (vDCId && template) {
    return {
      fromTemplate: form.fromTemplate,
      powerOn: form.powerOn,
      vDCId,
      name: form.name,
      hostname: form.hostname,
      description: form.description,
      template
    }
  } else return {}
};

interface ICheckVMQuota {
  customer: ICustomer | null;
  stats: {
    nbOfVMs: number;
    nbOfCPUs: number;
    maxMemory: number;
    maxStorage: number;
  };
  form: IVMForm;
  vmsTemplates: IVMsTemplate[];
}

export const checkVMQuota = ({ customer, stats, form, vmsTemplates }: ICheckVMQuota) => {
  const customerResources = customer?.resources[0];
  if (!customerResources) return 'Customer resources not found';

  const errors = [];

  if (stats.nbOfVMs + 1 > customerResources.nbOfVMs) {
    errors.push('VMs')
  }

  if (form.fromTemplate) {
    const template = vmsTemplates.find(one => one.id === form.template);
    if (!template) return 'Template not found';

    if (stats.nbOfCPUs + template.numberOfCpus > customerResources.nbOfCPUs) {
      errors.push('CPUs');
    }
    if (stats.maxMemory + Math.floor(template.memoryMB / 1024) > customerResources.maxMemory) {
      errors.push('Memory');
    }
    if (stats.maxStorage + Math.floor(template.totalStorageAllocatedMb / 1024) > customerResources.maxStorage) {
      errors.push('Storage');
    }
  } else {
    const plan = plans.find(plan => plan.type === form.plan);
    if (!plan) return 'Plan not found';

    if (stats.nbOfCPUs + plan.cpu > customerResources.nbOfCPUs) {
      errors.push('VMs');
    }
    if (stats.maxMemory + Math.floor(plan.memory / 1024) > customerResources.maxMemory) {
      errors.push('Memory');
    }
    if (stats.maxStorage + plan.storage > customerResources.maxStorage) {
      errors.push('Storage');
    }
  }

  return errors.length > 0 ? `Not enough number of ${errors.join(', ')}. Please contact the Administrator` : '';
}
