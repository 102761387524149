export default {
  labels: ['1994', '1996', '1998', '2000', '2002', '2004', '2006', '2008'],
  datasets: [
    {
      label: 'Brandy',
      data: [5, 7, 28, 5, 2, 3, 2, 1],
      fill: false,
      backgroundColor: '#43BAF2',
      borderColor: '#43BAF2',
      borderWidth: 2,
      lineTension: 0,
    },
    {
      label: 'Wiskey',
      data: [1, 2, 1, 1, 2, 2, 3, 4],
      fill: false,
      backgroundColor: '#1676D2',
      borderColor: '#1676D2',
      borderWidth: 2,
      lineTension: 0,
    },
    {
      label: 'Tequila',
      data: [2, 3, 4, 8, 5, 1, 2, 3],
      fill: false,
      backgroundColor: '#EE6B00',
      borderColor: '#EE6B00',
      borderWidth: 2,
      lineTension: 0,
    },
  ],
};
