import React, { InputHTMLAttributes } from "react";
import { Input, Label, Wrapper } from "./styles";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  format?: "sm" | "md" | "lg";
}

export default function Checkbox({ label, format = "sm", ...rest }: IProps) {
  return (
    <Wrapper>
      <Input type="checkbox" sz={format} {...rest} />
      <Label>{label}</Label>
    </Wrapper>
  );
}
