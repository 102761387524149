import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  max-width: min-content;
  max-height: min-content;
`;

export const Title = styled.h3`
  font-weight: normal;
  font-size: 11px;
  text-align: left;
  color: #888;
  margin: 0;
`;

export const FloatingButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 32px;
  right: 10px;
  width: 24px;
  height: 24px;
  background: #fff;
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.16));
  > svg {
    color: #4472c4;
    stroke-width: 1px;
  }
`;
