import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 80%;
  margin: 10rem auto;
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface ICellRows {
  shouldHandleClick: boolean;
}

export const CellRows = styled.div<ICellRows>`
  width: 100%;
  ${({ shouldHandleClick }) => !shouldHandleClick && `
    cursor: default;
  `}
`;

export const CellRow = styled.div`
  padding: 1rem;
  min-height: 5rem;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
`;

interface IIsUploadedWrapper {
  isActive: boolean;
}

export const IsUploadedWrapper = styled.div<IIsUploadedWrapper>`
  font-size: 1.6rem;
  ${({ theme, isActive }) => `color: ${isActive ? theme.palette.success.main : theme.palette.danger.main}`};
`;

interface IIsExpiredWrapper {
  isExpired: boolean;
}

export const IsExpiredWrapper = styled.div<IIsExpiredWrapper>`
  span {
    ${({ theme, isExpired }) => `color: ${isExpired ? theme.palette.danger.main : theme.palette.success.main}`};
    ${({ isExpired }) => `background-color: ${isExpired ? "#FDEEEE" : "#F1FFFC"}`};
    padding: .5rem;
    font-weight: bold;
  }
`;
