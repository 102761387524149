import CardStatus from "features/virtual/components/CardStatus";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { FaPlay, FaStop } from "react-icons/fa";
import { VscRefresh } from "react-icons/vsc";
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery
} from "react-query";
import { OptionTypeBase } from "react-select";
import { IMediaImage, IVM, IVMDetails } from "types/virtual";
import Button from "ui/Button";
import Select from "ui/Select";
import Spinner from "ui/Spinner";
import api from "utils/api";
import EditSettingsForm from "../EditSettingsForm";
import LineChart from "../LineChart";
import MountMediaModal from "../MountMediaModal";
import {
  ChartsWrapper,
  CloseButton,
  Content,
  EmptyVMPictureWrapper,
  Header,
  LeftSection,
  MountWrapper,
  RightSection,
  SlideAnimationDuration,
  StatusWrapper,
  Title,
  VMActionButton,
  VMActionsWrapper, VMHeadWrapper, VMPicture, VMPictureWrapper,
  VMStatusWrapper,
  Wrapper
} from "./styles";

interface IProps {
  vm: IVM;
  refetchVMs: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IVM[], unknown>>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function VMDetails({ vm, refetchVMs, onClose }: IProps) {
  const {
    id,
    name,
    status,
    isBusy,
    hardwareVersion,
    otherAttributes: { vmToolsVersion },
  } = vm;

  const [isVisible, setIsVisible] = useState(true);

  const [view, setView] = useState<OptionTypeBase | null>({
    label: "Day",
    value: "day",
  });

  const [openMountMedia, setOpenMountMedia] = useState(false);

  type IMountMediaModalType = "ISO" | "FLOPPY";

  const [mediaModalType, setMediaModalType] = useState<IMountMediaModalType>(
    "ISO"
  );

  const [openEditSettings, setOpenEditSettings] = useState(false);

  const { data: vmDetails } = useQuery(
    `vm-details-${id}-${isBusy}`,
    async () => {
      const { data } = await api.get(`/vcloud-director/virtual/vms/${id}`);
      return data as IVMDetails;
    }
  );

  type IActionsTypes =
    | "power-on"
    | "power-off"
    | "reset"
    | "install-vmware-tools";

  const { data: thumbnail, refetch: refetchThumbnail } = useQuery(
    `thumbnail-${id}`,
    async () => {
      const { data } = await api.get(
        `/vcloud-director/virtual/vms/${id}/thumbnail-image`
      );
      return data;
    },
    {
      onSuccess: () => {
        if (!thumbnail) refetchThumbnail();
      },
    }
  );

  interface IActionOptions {
    id: string;
    type: IActionsTypes;
  }

  const actionsMutation = useMutation(({ id, type }: IActionOptions) => {
    return api.post(`/vcloud-director/virtual/action/${type}`, {
      vmId: id,
    });
  });

  const ejectMediaMutation = useMutation(
    async (mediaImage: IMediaImage) =>
      await api.post("/vcloud-director/virtual/action/eject-media", {
        vmId: id,
        mediaImage: mediaImage,
      }),
    {
      onSuccess: () => {
        refetchVMs();
      },
    }
  );

  const handleViewChange = (view: OptionTypeBase | null) => {
    setView(view);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsVisible(false);
    if (onClose) {
      setTimeout(() => onClose(event), SlideAnimationDuration * 1000);
    }
  };

  const handlePowerOn = () => {
    actionsMutation.mutate({ id, type: "power-on" });
  };

  const handlePowerOff = () => {
    actionsMutation.mutate({ id, type: "power-off" });
  };

  const handleReset = () => {
    actionsMutation.mutate({ id, type: "reset" });
  };

  const handleInstallVMwareTools = () => {
    actionsMutation.mutate({ id, type: "install-vmware-tools" });
  };

  const handleMountMedia = (type: IMountMediaModalType) => {
    setMediaModalType(type);
    setOpenMountMedia(true);
  };

  const handleEjectMedia = (mediaImage: IMediaImage) => {
    ejectMediaMutation.mutate(mediaImage);
  };

  const handleConsole = () => {
    window.open(
      `/api/v1/vcloud-director/virtual/vms/${id}/console`,
      "_blank",
      ""
    );
  };

  const media = vmDetails?.section.find(
    ({ _type }) => _type === "VmSpecSectionType"
  )?.mediaSection.mediaSettings;

  const iso = media?.find(({ mediaType }) => mediaType === "ISO");
  const floppy = media?.find(({ mediaType }) => mediaType === "FLOPPY");

  const isIsoMounted = iso?.mediaImage;
  const isFloppyMounted = floppy?.mediaImage;

  return (
    <>
      <Wrapper isVisible={isVisible}>
        <Header>
          <Title>
            VM Details: <span>{name}</span>
          </Title>
          <CloseButton onClick={handleClose}>
            <CgClose strokeWidth="2px" />
          </CloseButton>
        </Header>
        <Content>
          <LeftSection>
            <VMHeadWrapper>
              <VMPictureWrapper onClick={handleConsole}>
                {thumbnail ? (
                  <VMPicture src={`data:image/png;base64,${thumbnail}`} />
                ) : (
                  <EmptyVMPictureWrapper>
                    <Spinner height={12} />
                  </EmptyVMPictureWrapper>
                )}
              </VMPictureWrapper>
              <StatusWrapper>
                <div>Uptime: 125hrs</div>
                <CardStatus status={status} isBusy={isBusy} />
              </StatusWrapper>
            </VMHeadWrapper>
            <VMActionsWrapper>
              <VMActionButton onClick={handlePowerOn}>
                <FaPlay />
                Start
              </VMActionButton>
              <VMActionButton onClick={handlePowerOff}>
                <FaStop />
                Stop
              </VMActionButton>
              <VMActionButton onClick={handleReset}>
                <VscRefresh />
                Reset
              </VMActionButton>
            </VMActionsWrapper>
            <VMStatusWrapper>
              <div>
                Alarm details: <span>Yes</span>
              </div>
              <div
                onClick={() =>
                  iso?.mediaImage
                    ? handleEjectMedia(iso.mediaImage)
                    : handleMountMedia("ISO")
                }
              >
                {isIsoMounted ? (
                  <MountWrapper>
                    Eject CD: <span>({iso?.mediaImage?.name})</span>
                  </MountWrapper>
                ) : (
                  <MountWrapper>Mount CD</MountWrapper>
                )}
              </div>
              <div
                onClick={() =>
                  floppy?.mediaImage
                    ? handleEjectMedia(floppy.mediaImage)
                    : handleMountMedia("FLOPPY")
                }
              >
                {isFloppyMounted ? (
                  <MountWrapper>
                    Eject Floppy: <span>({floppy?.mediaImage?.name})</span>
                  </MountWrapper>
                ) : (
                  <MountWrapper>Mount Floppy</MountWrapper>
                )}
              </div>
              <div>
                VMTools version:
                <span>
                  {vmToolsVersion == "0" ? "Not Installed" : vmToolsVersion}
                </span>
              </div>
              {vmToolsVersion == "0" && (
                <div>
                  <Button variant="link" onClick={handleInstallVMwareTools}>
                    Install VMTools
                  </Button>
                </div>
              )}
              <div>
                Hardware version: <span>{hardwareVersion}</span>
              </div>
              <Button onClick={() => setOpenEditSettings(true)}>
                Edit Settings
              </Button>
            </VMStatusWrapper>
          </LeftSection>
          <RightSection>
            <Select
              label="Current view"
              options={[
                { label: "Day", value: "day" },
                { label: "Month", value: "month" },
                { label: "Year", value: "year" },
              ]}
              value={view}
              onChange={handleViewChange}
              width="10em"
              withError={false}
            />
            <ChartsWrapper>
              <LineChart
                title={`Trend of Sales of the Most Popular Products of ${name}`}
              />
              <LineChart
                title={`Trend of Sales of the Most Popular Products of ${name}`}
              />
              <LineChart
                title={`Trend of Sales of the Most Popular Products of ${name}`}
              />
            </ChartsWrapper>
          </RightSection>
        </Content>
      </Wrapper>

      <MountMediaModal
        vmId={id}
        type={mediaModalType}
        open={openMountMedia}
        setOpen={setOpenMountMedia}
        refetchVMs={refetchVMs}
      />

      <EditSettingsForm
        vm={vm}
        open={openEditSettings}
        setOpen={setOpenEditSettings}
        refetchVMs={refetchVMs}
      />
    </>
  );
}
