import React from "react";
import Loader from "react-loader-spinner";
import { Wrapper } from "./styles";

type SpinnerProps = {
  width?: number;
  height?: number;
  color?: string;
  marginTop?: number;
  marginLeft?: number;
};

export default function Spinner({
  width = 100,
  height = 100,
  color = "#3e66b0",
  marginTop = 0,
  marginLeft = 0,
}: SpinnerProps) {
  return (
    <Wrapper marginTop={marginTop} marginLeft={marginLeft}>
      <Loader type="TailSpin" color={color} width={width} height={height} />
    </Wrapper>
  );
}
