import React from 'react';
import Verify from 'features/authentication/verify';
import { UserProvider } from 'providers/user';

export const VerifyPage = () => {
  return (
    <UserProvider>
      <Verify />
    </UserProvider>
  );
};
