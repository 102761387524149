import React from "react";
import { Link } from "react-router-dom";

import SCOCloudSVG from "assets/svg/SCOCloudSVG";
import { Wrapper, HeaderWrapper, LogoWrapper } from "./styles";
import ToolNavbar from "../Navbar";

interface IProps {
  children: React.ReactNode;
  isNavbarHidden?: boolean;
}

export default function Layout({ children, isNavbarHidden }: IProps) {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Link to="/tool/customers/create">
          <LogoWrapper>
            <SCOCloudSVG />
          </LogoWrapper>
        </Link>
        {!isNavbarHidden && <ToolNavbar />}
      </HeaderWrapper>
      {children}
    </Wrapper>
  );
}
