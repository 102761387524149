import styled from '@emotion/styled';

export const Wrapper = styled.div``;

interface IChildrenWrapper {
  isMsp?: boolean;
}

export const ChildrenWrapper = styled.div<IChildrenWrapper>`
  margin: 3rem auto;
  width: 80%;
`;
