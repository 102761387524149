import React from "react";
import Flex from "ui/Flex";
import Spinner from "ui/Spinner";

import { Wrapper } from "./styles";

type IProps = {
  status: string;
  isBusy: boolean;
};

export default function CardStatus({ status, isBusy }: IProps) {
  return (
    <Wrapper status={status} isBusy={isBusy}>
      {isBusy ? (
        <Flex>
          Busy
          <Spinner marginLeft={10} height={10} width={10} color="#FFF" />
        </Flex>
      ) : (
        status
      )}
    </Wrapper>
  );
}
