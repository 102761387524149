import { IHardDisk } from 'types/virtual';

interface ICreateHardDiskPayload {
  size: number;
  unitNumber: number;
  vdcStorageProfileId: string;
}

export const createHardDiskPayload = ({ size, unitNumber, vdcStorageProfileId }: ICreateHardDiskPayload): IHardDisk => ({
  adapterType: "4",
  busNumber: 0,
  disk: null,
  diskId: "",
  iops: 0,
  otherAttributes: {},
  overrideVmDefault: false,
  sizeMb: size * 1024,
  storageProfile: {
    href: `https://mycloud.labmd.scocloud.com/api/vdcStorageProfile/${vdcStorageProfileId}`,
    id: `urn:vcloud:vdcstorageProfile:${vdcStorageProfileId}`,
    name: "*",
    otherAttributes: {},
    type: "application/vnd.vmware.vcloud.vdcStorageProfile+xml",
    vCloudExtension: [],
  },
  thinProvisioned: true,
  unitNumber,
  vCloudExtension: [],
  virtualQuantity: null,
  virtualQuantityUnit: "byte",
})
