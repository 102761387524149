import { IAuthState } from "types/authentication";

export const initialAuthState: IAuthState = {
    challenge: {valid: false},
    user: null,
    isLogggedIn: false,
    /* eslint-disable */
    signIn: () => {},
    signOut: () => {},
    fetchUser: () => {},
} 