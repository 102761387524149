import styled from "@emotion/styled";

export const Wrapper = styled.div`
margin-top: ${(props) => props.theme.spacing.md};
border: 1px solid ${(props) => props.theme.palette.divider};
border-bottom: 0px;
`;

interface IRowWrapperProps {
    colCount?: number;
} 

export const RowWrapper = styled.div<IRowWrapperProps>`
display: grid;
grid-template-columns: repeat(${props => props.colCount || 7}, minmax(60px, 1fr));
align-items: center;
border-bottom: 1px solid ${(props) => props.theme.palette.divider};
padding: ${(props) => props.theme.spacing.md};
`;

export const RowItem = styled.span`
text-align: left;
padding: ${(props) => props.theme.spacing.sm};
`;

export const RowItemHeader = styled.strong`
text-align: left;
padding: ${(props) => props.theme.spacing.sm};
`;
