import React from "react";

import Dashboard from "features/dashboard/modules/Dashboard";
import Layout from "ui/Layout";

export default function DashboardPage() {
  return (
    <Layout isMsp>
      <Dashboard />
    </Layout>
  );
}
