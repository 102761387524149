import React from "react";
import { Doughnut, defaults } from "react-chartjs-2";

import {
  Wrapper,
  Content,
  Label,
  ValueWrapper,
  UsedWrapper,
  UsedMsg,
  UsedValue,
  TotalValue,
} from "./styles";

export interface IProps {
  type: string;
  used: number;
  total?: number;
}

export default function StatsCircle({ type, used, total = 0 }: IProps) {
  const remaining = total - used;

  const data = {
    datasets: [
      {
        data: [used, remaining],
        backgroundColor: ["orange", "green"],
        cutout: 80,
      },
    ],
  };

  return (
    <Wrapper>
      <Doughnut data={data} />
      <Content>
        <Label>{type}</Label>
        <ValueWrapper>
          <UsedWrapper>
            <UsedValue>{used}</UsedValue>
            <UsedMsg>Used</UsedMsg>
          </UsedWrapper>
          <TotalValue>/{total}</TotalValue>
        </ValueWrapper>
      </Content>
    </Wrapper>
  );
}

defaults.animation = false;
