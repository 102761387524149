import styled from "@emotion/styled";

export const customStyles = {
  table: {
    style: {
      overflow: 'visible'
    },
  },
  expanderRow: {
    style: {
      overflow: 'visible'
    },
  },
  tableWrapper: {
    style: {
      border: "1px solid rgba(0,0,0,.12)",
    },
  },
  headRow: {
    style: {
      padding: "1rem",
      backgroundColor: "#F5F9FF",
    },
  },
  rows: {
    style: {
      padding: "1rem",
    },
  },
};

export const Wrapper = styled.div`
  position: relative;
  > div {
    overflow-y: visible;
  }
`;

export const Header = styled.div`
  min-height: 7rem;
  max-width: calc(100% - 60rem);
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  @media (max-width: 1750px) {
    & > div,
    & > span {
      margin-bottom: 1rem;
    }
  }
`;

interface ITablePaginationWrapperProps {
  paginationTopRight?: boolean;
}

export const TablePaginationWrapper = styled.div<ITablePaginationWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ paginationTopRight }) =>
    paginationTopRight &&
    `
    position: absolute;
    top: 0;
    right: 0;
    max-width: 60rem;
  `}
`;

export const EntriesInformation = styled.div`
  color: ${(props) => props.theme.palette.gray.main};
`;

export const Pages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`;

export const RowsPerPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`;

export const SearchWrapper = styled.span`
  position: relative;
  width: 30rem;
  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
  }
`;
