import Login from 'features/authentication/login';
import { UserProvider } from 'providers/user';
import React from 'react';

export const LoginPage = () => {
  return (
    <UserProvider>
      <Login />
    </UserProvider>
  );
};
