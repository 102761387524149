import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

type WrapperProps = {
  width?: string | number;
};

export const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => (width ? width : "100%")};
`;

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  unit?: string;
}

export const Input = styled.input<InputProps>`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  width: 100%;
`;

export const ErrorMsg = styled.div`
  color: ${(props) => props.theme.palette.error.main};
  font-style: italic;
  font-size: 1.2rem;
  min-height: 2rem;
`;
