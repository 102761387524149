import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  margin: 5rem auto;
  position: relative;
`;

export const StatsCirclesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const DataTableHeaderWrapper = styled.div`
  display: flex;
  button {
    margin-left: 1rem;
  }
  @media (max-width: 1750px) {
    button:first-of-type {
    margin-left: 0rem;
  }
  }
`;

export const CellLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.palette.primary.light};
`;

export const Cell = styled.div``;

export const CellState = styled.div``;

interface IAlarmCellProps {
  color: string;
}
export const AlarmCell = styled.div<IAlarmCellProps>`
  color: ${(props) => props.color};
  font-weight: 900;
`;

export const NoAlarmCell = styled.div``;
