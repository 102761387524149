import styled from "@emotion/styled";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.a`
  border: none;
  color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 2px;
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  background-color:  ${({ theme }) => theme.palette.success.main};
  font-size: 1.6rem;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingMessage = styled.h2``;

export const UploadWrapper = styled.div`
  height: 40rem;
  width: 50rem;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgray' stroke-width='3' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 10rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const UploadTitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const UploadMessage = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.gray.main}
`;

export const UploadSuccessWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.success.main};
  svg { 
    font-size: 10rem;
  }
  button {
    font-size: 2rem;
  }
`;

export const UploadedSuccessMessage = styled.h1`
  margin: 3rem;
  font-weight: 400;
`;
