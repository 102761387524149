import React from "react";

import SelectRadio from "ui/SelectRadio";
import { IVMsTemplate } from "types/virtual";
import {
  RowWrapper,
  RowItem,
  RowItemHeader,
  Wrapper,
} from "ui/RadioButtonsTable";

import { TemplateRows } from "./styles";

interface IVMTemplateRowProps {
  template: IVMsTemplate;
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const VMTemplateRow = ({ template, handleChange }: IVMTemplateRowProps) => (
  <RowWrapper>
    <SelectRadio
      type="radio"
      name="template"
      onChange={handleChange}
      value={template.id}
    />
    <RowItem>{template.name}</RowItem>
    <RowItem>{template.containerName}</RowItem>
    <RowItem>{template.catalogName}</RowItem>
    <RowItem>{template.guestOs}</RowItem>
    <RowItem>
      <div>Memory: {(template.memoryMB / 1024).toFixed(2)} GB</div>
      <div>CPU: {template.numberOfCpus} </div>
    </RowItem>
    <RowItem>{template.totalStorageAllocatedMb / 1024} GB</RowItem>
  </RowWrapper>
);

const Header = () => (
  <RowWrapper>
    <RowItemHeader></RowItemHeader>
    <RowItemHeader>name</RowItemHeader>
    <RowItemHeader>vApp Name</RowItemHeader>
    <RowItemHeader>Catalog</RowItemHeader>
    <RowItemHeader>Os</RowItemHeader>
    <RowItemHeader>Compute</RowItemHeader>
    <RowItemHeader>Storage</RowItemHeader>
  </RowWrapper>
);

interface IProps {
  vmsTemplates: IVMsTemplate[];
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

export default function VMsTemplates({ vmsTemplates, handleChange }: IProps) {
  return (
    <Wrapper>
      <Header />
      <TemplateRows>
        {vmsTemplates.map((vmsTemplate) => (
          <VMTemplateRow
            key={vmsTemplate.id}
            template={vmsTemplate}
            handleChange={handleChange}
          />
        ))}
      </TemplateRows>
    </Wrapper>
  );
}
