import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 20rem;
  margin: 0 2rem;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Label = styled.label`
  font-size: 2.2rem;
  font-weight: bold;
`;

export const ValueWrapper = styled.div`
  display: flex;
`;

export const UsedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const UsedValue = styled.div`
  font-weight: bold;
  font-size: 2.8rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const UsedMsg = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

export const TotalValue = styled.div`
  color: ${({ theme }) => theme.palette.gray.main};
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 1rem;
`;


