import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  min-width: 30rem;
`;

export const HardDiskFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding-bottom: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  button:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const SpinnerWrapper = styled.div`
  height: 3rem;
  padding: 1rem;
  margin: 1rem;
`;
