import DataCenterForm from "features/tool/components/DataCenterForm";
import Layout from "features/tool/components/Layout";
import useValidate from "features/tool/hooks/useValidate";
import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { IDataCenterForm } from "types/tool";
import Button from "ui/Button";
import api from "utils/api";
import { Wrapper } from "./styles";

export default function CreateDataCenter() {
  const [form, setForm] = useState<IDataCenterForm>({ name: "", vCloudURL: "",cpuSpeed: 0 });
  const { errors, touched, handleBlur, handleFocus, setTouched } = useValidate()
  const history = useHistory();

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: value });
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  

  const mutation = useMutation(
    async () => api.post(`/tool/datacenters`, form),
    {
      onSuccess: () => {
        history.push("/tool/settings/datacenters");
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Layout>
      <Wrapper>
        <DataCenterForm
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          errors={errors}
          touched={touched}
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        >
          <Button type="submit" disabled={Object.keys(errors).length !== 0}>
            <FaSave /> Create
          </Button>
        </DataCenterForm>
      </Wrapper>
    </Layout>
  );
}
