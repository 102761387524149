import styled from "@emotion/styled";
import { ElementType } from "react";
import { ButtonProps } from ".";

type WrapperProps = {
  variant: ButtonProps["variant"]
  color: ButtonProps["color"]
  /* eslint-disable */
  as?: ElementType<any> | undefined
  htmlFor?: string
}

export const Wrapper = styled.button<WrapperProps>`
  border: none;
  color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 2px;
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  ${({ theme, variant, color }) => variant === "contained" && `
    background-color:  ${color === "primary" ? theme.palette.primary.main : theme.palette.error.main};
  `}
  ${({ theme, variant, color }) => variant === "outlined" && `
    background-color:  ${color === "primary" ? "#eaeef5" : "#fbe6e4"};
    color: ${color === "primary" ? theme.palette.primary.main : theme.palette.error.main};
    font-weight: bold;
  `}
  ${({ theme, variant, color }) => variant === "text" && `
    color: ${color === "primary" ? theme.palette.primary.main : theme.palette.error.main};
    font-weight: bold;
    padding-left: 0;
  `}
  ${({ theme, variant, color }) => variant === "link" && `
    color: ${color === "primary" ? theme.palette.primary.main : theme.palette.error.main};
    padding: 0;
    display: contents;
    text-decoration: underline;
  `}
  ${({ theme, color }) => color === "default" && `
    color: ${theme.palette.primary.main};
    background-color: #DFDEDE;
    font-weight: bold;
  `}
  &:focus {
    border: none;
    outline: none;
  }
  svg {
    margin: 0 3px;
  }
  &:disabled{
    background-color: #DFDEDA;
  }
`;
