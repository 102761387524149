import Customers from 'features/tool/modules/Customers';
import { UserProvider } from 'providers/user';
import React from 'react';

export default function ToolCustomersPage() {
  return (
    <UserProvider>
      <Customers />
    </UserProvider>
  );
}
