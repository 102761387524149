import React, { ReactNode } from "react";
import Navbar from "ui/Navbar";
import { ChildrenWrapper, Wrapper } from "./styles";

interface IProps {
  children: ReactNode;
  isMsp?: boolean;
}

export default function Layout({ children, isMsp }: IProps) {
  return (
    <Wrapper>
      {isMsp && <Navbar />}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
}
