import React, { useRef, useState } from "react";

import VAppSvg from "assets/svg/VApp";
import VMSvg from "assets/svg/VM";
import useOutsideAlerter from "features/virtual/hooks/useOutsideAlerter";
import DropDown from "ui/DropDown";
import Flex from "ui/Flex";
import {
  ActionsWrapper,
  Desc,
  HeaderWrapper,
  InfoName,
  InfoValue,
  InfoWrapper,
  MenuWrapper,
  RowsWrapper,
  RowWrapper,
  SpecValue,
  SpecWrapper,
  TitleWrapper,
  Wrapper,
} from "./styles";

type CardWrapper = {
  children: React.ReactNode;
};

export const Card = ({ children }: CardWrapper) => {
  return <Wrapper>{children}</Wrapper>;
};

type CardHeaderProps = {
  children: React.ReactNode;
  isVApp: boolean;
};

export const CardHeader = ({ children, isVApp }: CardHeaderProps) => {
  return <HeaderWrapper isVApp={isVApp}>{children}</HeaderWrapper>;
};

type CardTitleProps = {
  title: string;
  isVApp: boolean;
};

export const CardTitle = ({ isVApp, title }: CardTitleProps) => {
  return (
    <TitleWrapper>
      {isVApp ? <VAppSvg /> : <VMSvg />}
      <h3>{title}</h3>
    </TitleWrapper>
  );
};

type CardSpecProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  value: string | number;
  desc: string;
};

export const CardSpec = ({ Icon, value, desc }: CardSpecProps) => {
  return (
    <SpecWrapper>
      <Flex>
        <Icon />
        <SpecValue>{value}</SpecValue>
      </Flex>
      <Desc>{desc}</Desc>
    </SpecWrapper>
  );
};

type CardInfoProps = {
  name: string;
  value: string;
};

export const CardInfo = ({ name, value }: CardInfoProps) => {
  return (
    <InfoWrapper>
      <InfoName>{name}</InfoName>
      <InfoValue>{value}</InfoValue>
    </InfoWrapper>
  );
};

type CardActionsProps = {
  children?: React.ReactNode;
  isVApp: boolean;
  id: string;
  status: string;
  actions?: React.ReactNode;
};

export const CardActions = ({
  children,
  isVApp,
  id,
  status,
  actions,
}: CardActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  useOutsideAlerter(menuRef, setIsOpen);
  return (
    <ActionsWrapper>
      {children}
      <MenuWrapper
        isVApp={isVApp}
        onClick={() => setIsOpen(!isOpen)}
        ref={menuRef}
      >
        ...
        {isOpen && (
          <DropDown id={id} status={status}>
            {actions}
          </DropDown>
        )}
      </MenuWrapper>
    </ActionsWrapper>
  );
};

type CardRowsProps = {
  children: React.ReactNode;
};

export const CardRows = ({ children }: CardRowsProps) => {
  return <RowsWrapper>{children}</RowsWrapper>;
};

type CardRowProps = {
  children: React.ReactNode;
  withBorder?: boolean;
};

export const CardRow = ({ children, withBorder }: CardRowProps) => {
  return <RowWrapper withBorder={withBorder}>{children}</RowWrapper>;
};
