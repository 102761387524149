import Button from 'ui/Button';

import styled from "@emotion/styled";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem 2rem;
  margin-bottom: 4rem;
  border: 1px solid ${({ theme }) => theme.palette.gray.main};
  border-radius: 1rem;
  input {
    margin-bottom: 1rem;
  }
`;

export const CustomerInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const ResourceWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 2fr 2fr 1fr 1fr 1fr 1fr 0.5fr;
  column-gap: 2rem;
  align-items: center;
  justify-items: center;
`;

export const ResourcesActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
  button {
    margin-left: 1rem;
    position: relative;
    top: 1.9rem;
    height: 36px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const DeleteResourceBtn = styled(Button)`
  svg {
    color: ${({ theme }) => theme.palette.error.main};
    font-size: 2rem;
  }
`;

export const RadioWrapper = styled.div`
  align-self: start;
  input {
    margin-top: 1rem;
    margin-left: 1.5rem;
  }
`;
