import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background-color: #f0f5ff;
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const Item = styled.div`
  font-size: 1.5rem;
`;

export const MspInfos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MspInfosItem = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.6rem;
  > span {
    font-weight: 900;
    margin-left: 2rem;
    margin-left: 2rem;
  }
`;

