import React, { createContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { IAuthState, IChallenge, User } from 'types/authentication';
import api from 'utils/api';
import { initialAuthState } from 'utils/constants';

const UserContext = createContext({} as IAuthState);

interface IProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: IProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [state, setState] = useState<IAuthState>(initialAuthState);

  const signIn = (user: User) => {
    setState({
      ...state,
      user,
      isLogggedIn: true,
    });
  };

  const fetchUser = async () => {
    try {
      const { data, status } = await api.get(
        '/me/?$expand=iams&$select=name.first,email,iams&$sess=true'
      );
      if (status === 200 && data) {
        let challenge = data?.session.challenges || [];

        challenge = challenge.filter(({ valid }: IChallenge) => !valid)[0];

        if (challenge !== null && challenge !== undefined) {
          setState((state) => ({
            ...state,
            challenge,
            user: data,
            isLogggedIn: true, // To review
          }));

          push(
            !(challenge as IChallenge).initialised
              ? '/tool/2fa/setup'
              : '/tool/2fa/verify'
          );
        } else {
          setState((state) => ({
            ...state,
            challenge: { valid: true, initialised: true },
            user: data,
            isLogggedIn: true,
          }));
          redirect(true);
        }
      } else {
        setState({ ...state, isLogggedIn: false });
        redirect();
      }
    } catch (error) {
      console.error(error);
      redirect();
    }
  };

  const redirect = (isLoggedIn = false) => {
    if (isLoggedIn) {
      // redirection for logged in users
      switch (pathname) {
        case '/tool/login':
        case '/tool/2fa/setup':
        case '/tool/2fa/verify':
          push('/tool/customers/create');
          break;
        default:
          break;
      }
    } else {
      // redirection for non logged in users
      switch (pathname) {
        case '/tool/login':
        case '/tool/2fa/setup':
        case '/tool/2fa/verify':
        case '/tool/customers/create':
        case '/tool/customers/update':
        case '/tool/settings/datacenters':
        case '/tool/settings/datacenters/create':
        case '/tool/settings/datacenters/update':
        case '/tool/notifications':
          push('/tool/login');
          break;
        default:
          break;
      }
    }
  };

  const signOut = () =>
    setState({
      ...state,
      isLogggedIn: false,
      challenge: {
        valid: false,
      },
    });

  React.useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ ...state, signIn, signOut, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);
