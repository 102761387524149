import { ThemeProvider } from "@emotion/react";
import { SetupPage } from "pages/authentication/2fa/setup";
import { VerifyPage } from "pages/authentication/2fa/verify";
import { LoginPage } from "pages/authentication/login";
import { RegisterPage } from "pages/authentication/register";
import DashboardPage from "pages/dashboard";
import NotificationsPage from "pages/notifications";
import CustomerProfilePage from "pages/profile";
import ToolCustomersPage from "pages/tool/customers";
import ToolCreateCustomerPage from "pages/tool/customers/Create";
import ToolUpdateCustomerPage from "pages/tool/customers/Update";
import ToolUploadLicensePage from "pages/tool/licenses/Upload";
import ToolDataCentersPage from "pages/tool/settings/datacenters";
import ToolDCreateDataCenterPage from "pages/tool/settings/datacenters/Create";
import ToolUpdateDataCenterPage from "pages/tool/settings/datacenters/Update";
import VMsPage from "pages/vms";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Switch } from "react-router";
import theme from "theme";
import GlobalStyles from "theme/global-styles";




export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Switch>
        <Route exact path="/profile" component={CustomerProfilePage} />
        <Route
          exact
          path="/tool/licenses/upload"
          component={ToolUploadLicensePage}
        />
        
        <Route
          exact
          path="/tool/customers/create"
          component={ToolCreateCustomerPage}
        />
        <Route
          exact
          path="/tool/customers/update"
          component={ToolCustomersPage}
        />
        <Route
          exact
          path="/tool/customers/update/:id"
          component={ToolUpdateCustomerPage}
        />
        <Route
          exact
          path="/tool/settings/datacenters"
          component={ToolDataCentersPage}
        />
        <Route
          exact
          path="/tool/settings/datacenters/create"
          component={ToolDCreateDataCenterPage}
        />
        <Route
          exact
          path="/tool/settings/datacenters/update/:id"
          component={ToolUpdateDataCenterPage}
        />
        <Route exact path="/tool/notifications" component={NotificationsPage} />
        <Route
          exact
          path="/tool/licenses/upload"
          component={ToolUploadLicensePage}
        />
        <Route exact path="/vms" component={VMsPage} />
        <Route
          exact
          path="/tool/login"
          component={LoginPage}
        />
        <Route
          exact
          path="/tool/register"
          component={RegisterPage}
        />
        <Route
          exact
          path="/tool/2fa/verify"
          component={VerifyPage}
        />
        <Route
          exact
          path="/tool/2fa/setup"
          component={SetupPage}
        />
        <Route exact path="/" component={DashboardPage} />
      </Switch>
    </ThemeProvider>
  );
}
