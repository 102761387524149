import DataCenters from 'features/tool/modules/DataCenters';
import { UserProvider } from 'providers/user';
import React from 'react';

export default function ToolDataCentersPage() {
  return (
    <UserProvider>
      <DataCenters />
    </UserProvider>
  );
}
