import Register from 'features/authentication/register'
import { UserProvider } from 'providers/user'
import React from 'react'

export const RegisterPage = () => {
    
    return (
        <UserProvider>

            <Register/>
        </UserProvider>
    )
}