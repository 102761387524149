import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 10px;
  padding: ${(props) => props.theme.spacing.lg};
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${(props) => props.theme.spacing.xlg};
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.4rem;
`;

export const ForgetPassword = styled.h5`
  margin-top: 2px;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const SignIn = styled.a`
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  margin-top: 2px;
  color: ${(props) => props.theme.palette.primary.main};
`;
