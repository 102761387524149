import React from 'react';
import Setup from 'features/authentication/setup';
import { UserProvider } from 'providers/user';

export const SetupPage = () => {
  return (
    <UserProvider>
      <Setup />
    </UserProvider>
  );
};
