import useValidate from "features/tool/hooks/useValidate";
import React, { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useQuery } from "react-query";
import { ActionMeta, OptionTypeBase } from "react-select";
import {
  ICustomerForm,
  IDataCenter,
  INetworkPool,
  IProvider,
  IStorageProfile,
} from "types/tool";
import Button from "ui/Button";
import Label from "ui/Label";
import RadioInput from "ui/RadioInput";
import Select from "ui/Select";
import TextField from "ui/TextField";
import api from "utils/api";
import { getRecord } from "utils/parser";
import { updateObject } from "utils/tool";
import {
  ActionsWrapper,
  CustomerInfoWrapper,
  DeleteResourceBtn,
  ResourcesActionsWrapper,
  ResourceWrapper,
  Wrapper,
  RadioWrapper,
} from "./styles";

interface IProps {
  children: React.ReactNode;
  initForm: ICustomerForm;
  form: ICustomerForm;
  isUpdate?: boolean;
  setForm: React.Dispatch<React.SetStateAction<ICustomerForm>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export default function CustomerForm({
  children,
  form,
  isUpdate,
  setForm,
  handleSubmit: submitForm,
}: IProps) {
  const [dataCenter, setDataCenter] = useState("");
  const { data: dataCenters = [], isLoading: isDataCentersLoading } = useQuery(
    "fetchDataCenters",
    async () => {
      const response = await api.get("/tool/datacenters");
      const data = getRecord(response) as IDataCenter[];
      return data.map((one) => ({ label: one.name, value: one.name }));
    }
  );

  //TODO: Filter by data center
  const { data: providers = [], isLoading: isProvidersLoading } = useQuery(
    "fetchProviders",
    async () => {
      const response = await api.get("/vcloud-director/query", {
        params: { type: "providerVdc" },
      });
      const data = getRecord(response) as IProvider[];
      return data.map((one) => ({ label: one.name, value: one.name }));
    }
  );

  //TODO: Filter by provider
  const {
    data: networkPools = [],
    isLoading: isNetworkPoolsLoading,
  } = useQuery("fetchNetworkPools", async () => {
    const response = await api.get("/network/networkPool");
    const data = getRecord(response) as INetworkPool[];
    return data.map((one) => ({ label: one.name, value: one.name }));
  });

  const {
    data: storageProfiles = [],
    isLoading: isStoragePoolsLoading,
  } = useQuery("fetchStorageProfiles", async () => {
    const response = await api.get("/vcloud-director/query", {
      params: { type: "providerVdcStorageProfile" },
    });
    const data = getRecord(response) as IStorageProfile[];
    return data.map((one) => ({ label: one.name, value: one.name }));
  });

  const {
    errors,
    touched,
    handleBlur,
    handleFocus,
    setTouched,
    validate,
    validateMany,
  } = useValidate();

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm(updateObject(form, name, value));

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleResourceTextChange = ({
    currentTarget: { name, value, dataset },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      resources: form.resources.map((resource) => {
        if (dataset.dataCenter === resource.dataCenter) {
          return {
            ...resource,
            [name]: !value ? "0" : value.replace(/^0+/, ""),
          };
        }
        return resource;
      }),
    });
  };

  const handleResourceSelectChange = (
    option: OptionTypeBase | null,
    { name }: ActionMeta<OptionTypeBase>
  ) => {
    if (!option) return;
    if (name === "dataCenter") {
      setDataCenter(option.value);
    } else {
      setForm({
        ...form,
        resources: form.resources.map((resource) => {
          if (resource.dataCenter == option.dataCenter && name) {
            return { ...resource, [name]: option.value };
          }
          return resource;
        }),
      });
    }
  };

  const handleResourceSelectBlur = (
    name: string,
    value: string,
    min?: number,
    max?: number
  ) => {
    return handleBlur({
      currentTarget: {
        name,
        value,
        min: min?.toString(),
        max: max?.toString(),
      },
    } as React.FormEvent<HTMLInputElement>);
  };

  const handleResourceSelectFocus = (
    name: string,
    value: string,
    min?: number,
    max?: number
  ) => {
    return handleFocus({
      currentTarget: {
        name,
        value,
        min: min?.toString(),
        max: max?.toString(),
      },
    } as React.FormEvent<HTMLInputElement>);
  };

  const handleResourceRadioChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({ ...form, defaultDataCenter: value });
  };

  const handleAddResource = () => {
    if (!dataCenter) return;
    setForm({
      ...form,
      defaultDataCenter: form.defaultDataCenter
        ? form.defaultDataCenter
        : dataCenter,
      resources: [
        ...form.resources,
        {
          dataCenter,
          provider: "",
          networkPool: "",
          storageProfile: "",
          nbOfVMs: 0,
          nbOfCPUs: 0,
          maxMemory: 0,
          maxStorage: 0,
        },
      ],
    });
    setDataCenter("");
    setTouched({
      ...touched,
      dataCenter: false,
    });
  };

  const handleDeleteResource = ({
    currentTarget: { dataset },
  }: React.FormEvent<HTMLButtonElement>) => {
    const resources = form.resources.filter(
      (resource) => resource.dataCenter !== dataset.dataCenter
    );

    const defaultDataCenter =
      form.defaultDataCenter === dataset.dataCenter && resources.length === 0
        ? ""
        : form.defaultDataCenter === dataset.dataCenter
        ? resources[0].dataCenter
        : form.defaultDataCenter;

    setForm({
      ...form,
      defaultDataCenter,
      resources,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    let isValid = true;

    if (form.resources.length === 0) {
      const value = dataCenter ? form.resources.length.toString() : dataCenter;
      isValid = await validate("dataCenter", value, { isTouched: true });
    } else {
      isValid = await validateMany(
        form.resources
          .map((resource, index) => [
            {
              name: `resources.provider[${index}]`,
              value: resource.provider,
              isTouched: true,
            },
            {
              name: `resources.networkPool[${index}]`,
              value: resource.networkPool,
              isTouched: true,
            },
            {
              name: `resources.storageProfile[${index}]`,
              value: resource.storageProfile,
              isTouched: true,
            },
            {
              name: `resources.nbOfVMs[${index}]`,
              value: resource.nbOfVMs.toString(),
              isTouched: true,
            },
            {
              name: `resources.nbOfCPUs[${index}]`,
              value: resource.nbOfCPUs.toString(),
              isTouched: true,
              min: resource.nbOfVMs > 0 ? resource.nbOfVMs : 1,
            },
            {
              name: `resources.maxMemory[${index}]`,
              value: resource.maxMemory.toString(),
              isTouched: true,
              min: resource.nbOfVMs > 0 ? resource.nbOfVMs : 1,
            },
            {
              name: `resources.maxStorage[${index}]`,
              value: resource.maxStorage.toString(),
              isTouched: true,
              min: resource.nbOfVMs > 0 ? resource.nbOfVMs : 1,
            },
          ])
          .reduce((acc, cur) => [...acc, ...cur], [])
      );
    }

    if (!isValid) {
      e.preventDefault();
      return;
    }

    submitForm(e);
  };

  const addedDataCenters = form.resources.map((one) => one.dataCenter);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <CustomerInfoWrapper>
        <TextField
          label="Organization Name"
          name="orgName"
          width="30%"
          value={form.orgName}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["orgName"]}
          touched={touched["orgName"]}
          disabled={isUpdate}
          required
        />
        <TextField
          label="Organization Name Prefix"
          name="orgPrefix"
          width="30%"
          value={form.orgPrefix}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["orgPrefix"]}
          touched={touched["orgPrefix"]}
          disabled={isUpdate}
          required
        />
        <TextField
          label="Customer ID"
          name="customerID"
          width="30%"
          value={form.customerID}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["customerID"]}
          touched={touched["customerID"]}
          required
        />
        <TextField
          label="MSP name"
          name="msp"
          width="30%"
          value={form.msp}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["msp"]}
          touched={touched["msp"]}
          required
        />
        <TextField
          label="Address"
          name="address"
          width="30%"
          value={form.address}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["address"]}
          touched={touched["address"]}
          required
        />
        <TextField
          label="Phone number"
          name="phone"
          width="30%"
          value={form.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["phone"]}
          touched={touched["phone"]}
          required
        />
        <TextField
          type="date"
          label="License Expiration Date"
          name="expirationDate"
          width="30%"
          value={form.expirationDate}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={handleFocus}
          error={errors["expirationDate"]}
          touched={touched["expirationDate"]}
          required
        />

        <TextField
          label="Administrator username"
          name="username"
          width="30%"
          value={form.username}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["username"]}
          touched={touched["username"]}
          required
        />
        {!isUpdate && (
          <TextField
            type="password"
            label="Password"
            name="password"
            width="30%"
            value={form.password}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["password"]}
            touched={touched["password"]}
            required
          />
        )}
        <TextField
          label="Email address"
          name="email"
          width="30%"
          value={form.email}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={errors["email"]}
          touched={touched["email"]}
          required
        />
      </CustomerInfoWrapper>
      {form.resources.map((resource, index) => {
        return (
          <ResourceWrapper key={index}>
            <RadioWrapper>
              <Label>Default</Label>
              <RadioInput
                name="defaultDataCenter"
                value={resource.dataCenter}
                checked={form.defaultDataCenter === resource.dataCenter}
                onChange={handleResourceRadioChange}
              />
            </RadioWrapper>

            <TextField
              label="Data center"
              name="dataCenter"
              value={resource.dataCenter}
              disabled={true}
              required
            />
            <Select
              label="Provider"
              name="provider"
              value={{ label: resource.provider, value: resource.provider }}
              options={providers.map((one) => ({
                ...one,
                dataCenter: resource.dataCenter,
              }))}
              isDisabled={isProvidersLoading}
              isLoading={isProvidersLoading}
              onChange={handleResourceSelectChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.provider[${index}]`,
                  resource.provider
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.provider[${index}]`,
                  resource.provider
                )
              }
              error={errors[`resources.provider[${index}]`]}
              touched={touched[`resources.provider[${index}]`]}
              required
            />
            <Select
              label="Network Pool"
              name="networkPool"
              value={{
                label: resource.networkPool,
                value: resource.networkPool,
              }}
              options={networkPools.map((one) => ({
                ...one,
                dataCenter: resource.dataCenter,
              }))}
              isDisabled={isNetworkPoolsLoading}
              isLoading={isNetworkPoolsLoading}
              onChange={handleResourceSelectChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.networkPool[${index}]`,
                  resource.networkPool
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.networkPool[${index}]`,
                  resource.networkPool
                )
              }
              error={errors[`resources.networkPool[${index}]`]}
              touched={touched[`resources.networkPool[${index}]`]}
              required
            />
            <Select
              label="Storage Profile"
              name="storageProfile"
              value={{
                label: resource.storageProfile,
                value: resource.storageProfile,
              }}
              options={storageProfiles.map((one) => ({
                ...one,
                dataCenter: resource.dataCenter,
              }))}
              isDisabled={isStoragePoolsLoading}
              isLoading={isStoragePoolsLoading}
              onChange={handleResourceSelectChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.storageProfile[${index}]`,
                  resource.storageProfile
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.storageProfile[${index}]`,
                  resource.storageProfile
                )
              }
              error={errors[`resources.storageProfile[${index}]`]}
              touched={touched[`resources.storageProfile[${index}]`]}
              required
            />
            <TextField
              type="number"
              label="VMs"
              name="nbOfVMs"
              data-data-center={resource.dataCenter}
              value={resource.nbOfVMs}
              onChange={handleResourceTextChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.nbOfVMs[${index}]`,
                  resource.nbOfVMs.toString()
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.nbOfVMs[${index}]`,
                  resource.nbOfVMs.toString()
                )
              }
              error={errors[`resources.nbOfVMs[${index}]`]}
              touched={touched[`resources.nbOfVMs[${index}]`]}
              required
            />
            <TextField
              type="number"
              label="CPUs"
              name="nbOfCPUs"
              data-data-center={resource.dataCenter}
              value={resource.nbOfCPUs}
              onChange={handleResourceTextChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.nbOfCPUs[${index}]`,
                  resource.nbOfCPUs.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.nbOfCPUs[${index}]`,
                  resource.nbOfCPUs.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              error={errors[`resources.nbOfCPUs[${index}]`]}
              touched={touched[`resources.nbOfCPUs[${index}]`]}
              required
            />
            <TextField
              type="number"
              label="Memory (GB)"
              name="maxMemory"
              data-data-center={resource.dataCenter}
              value={resource.maxMemory}
              onChange={handleResourceTextChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.maxMemory[${index}]`,
                  resource.maxMemory.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.maxMemory[${index}]`,
                  resource.maxMemory.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              error={errors[`resources.maxMemory[${index}]`]}
              touched={touched[`resources.maxMemory[${index}]`]}
              required
            />
            <TextField
              type="number"
              label="Storage (GB)"
              name="maxStorage"
              data-data-center={resource.dataCenter}
              value={resource.maxStorage}
              onChange={handleResourceTextChange}
              onBlur={() =>
                handleResourceSelectBlur(
                  `resources.maxStorage[${index}]`,
                  resource.maxStorage.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              onFocus={() =>
                handleResourceSelectFocus(
                  `resources.maxStorage[${index}]`,
                  resource.maxStorage.toString(),
                  resource.nbOfVMs > 0 ? resource.nbOfVMs : 1
                )
              }
              error={errors[`resources.maxStorage[${index}]`]}
              touched={touched[`resources.maxStorage[${index}]`]}
              required
            />
            <DeleteResourceBtn
              variant="text"
              type="button"
              data-data-center={resource.dataCenter}
              onClick={handleDeleteResource}
            >
              <FaTimes />
            </DeleteResourceBtn>
          </ResourceWrapper>
        );
      })}

      <ResourcesActionsWrapper>
        <Select
          label="Add data center"
          name="dataCenter"
          value={{ label: dataCenter, value: dataCenter }}
          options={dataCenters.filter(
            (one) => !addedDataCenters.includes(one.value)
          )}
          width="150px"
          isDisabled={isDataCentersLoading}
          isLoading={isDataCentersLoading}
          onChange={handleResourceSelectChange}
          onBlur={() => handleResourceSelectBlur("dataCenter", dataCenter)}
          onFocus={() => handleResourceSelectFocus("dataCenter", dataCenter)}
          withError={form.resources.length === 0}
          error={errors["dataCenter"]}
          touched={touched["dataCenter"]}
          required
        />
        <Button type="button" onClick={handleAddResource}>
          <FaPlus />
        </Button>
      </ResourcesActionsWrapper>
      <ActionsWrapper>{children}</ActionsWrapper>
    </Wrapper>
  );
}
