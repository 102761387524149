import styled from "@emotion/styled";

export const Wrapper = styled.div``;

interface ICellRows {
  shouldHandleClick: boolean;
}

export const CellRows = styled.div<ICellRows>`
  width: 100%;
  ${({ shouldHandleClick }) => !shouldHandleClick && `
    cursor: default;
  `}
`;

export const CellRow = styled.div`
  /* padding: 1rem; */
  min-height: 5rem;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
`;

interface IIsUploadedWrapper {
  isActive: boolean;
}

export const IsUploadedWrapper = styled.div<IIsUploadedWrapper>`
  font-size: 1.6rem;
  ${({ theme, isActive }) => `color: ${isActive ? theme.palette.success.main : theme.palette.danger.main}`};
`;

interface IIsExpiredWrapper {
  isExpired: boolean;
}

export const IsExpiredWrapper = styled.div<IIsExpiredWrapper>`
  span {
    ${({ theme, isExpired }) => `color: ${isExpired ? theme.palette.danger.main : theme.palette.success.main}`};
    ${({ isExpired }) => `background-color: ${isExpired ? "#FDEEEE" : "#F1FFFC"}`};
    padding: .5rem;
    font-weight: bold;
  }
`;

interface IIsActiveWrapper {
  isActive: boolean;
}

export const IIsActiveWrapper = styled.div<IIsActiveWrapper>`
  font-size: 2rem;
  cursor: pointer;
  display: inline;
  svg {
    ${({ theme, isActive }) => `
    color: ${isActive ? theme.palette.primary.main : "gray"};
    transform: ${isActive ? "scaleX(1)" : "scaleX(-1)"};
    `}
  }
`;



export const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
  button:not(:last-child) {
    margin-right: 2rem;
  }
`;



export const PasswordWrapper = styled.div`
  min-width: 50rem;
  padding: 3rem;
`;

export const PasswordUploadWrapper = styled.div`
  background-color: #f4f4f4;
  height: 20rem;
  width: 50rem;
  cursor: pointer;
  border: 1px solid #e2e2de;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 10rem;
    color: ${({ theme }) => theme.palette.gray.main};
  }
`;

export const PasswordUploadTitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface IPasswordUploadSubtitleProps {
  isError: boolean;
}

export const PasswordUploadSubtitle = styled.p<IPasswordUploadSubtitleProps>`
  font-size: 1.4rem;
  color: ${({ theme, isError }) => isError ? theme.palette.danger.main : theme.palette.gray.main};
`;

export const PasswordUploadMessage = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.gray.main}
`;

export const DecryptedPasswordWrapper = styled.div`
  font-size: 2.2rem;
  text-align: center;
  background-color: #f4f4f4;
  border: 1px solid #e2e2de;
  padding: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;
