import UpdateCustomer from 'features/tool/modules/UpdateCustomer';
import { UserProvider } from 'providers/user';
import React from 'react';

export default function ToolUpdateCustomerPage() {
  return (
    <UserProvider>
      <UpdateCustomer />
    </UserProvider>
  );
}
