import React from "react";

import { Heading } from "./styles";

interface IProps {
  children: React.ReactNode;
}

export default function Title({ children }: IProps) {
  return <Heading>{children}</Heading>;
}
