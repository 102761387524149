import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Wrapper, Item } from "./styles";

const routes = [
  {
    label: "New",
    pathname: "/tool/customers/create",
  },
  {
    label: "Update",
    pathname: "/tool/customers/update",
  },
  {
    label: "Settings",
    pathname: "/tool/settings/datacenters",
  },
];

export default function Navbar() {
  const location = useLocation();

  return (
    <Wrapper>
      {routes.map(({ label, pathname }) => (
        <Item isActive={location.pathname.includes(pathname)} key={pathname}>
          <Link to={pathname}>{label}</Link>
        </Item>
      ))}
    </Wrapper>
  );
}
