import { useState } from "react";
import { IKeyValue } from "types/common";
import { isValid } from "utils/validation";

interface Field {
  name: string;
  value: string;
  min?: number;
  max?: number;
  isTouched?: boolean;
}

interface ValidateOptions {
  min?: number;
  max?: number;
  isTouched?: boolean;
}

const useValidate = () => {
  const [errors, setErrors] = useState<IKeyValue>({});
  const [touched, setTouched] = useState<IKeyValue<boolean>>({});

  const validate = async (name: string, value: string, { min, max, isTouched }: ValidateOptions) => {
    const error: string | null = await isValid(name, value, min, max);
    const valid: boolean = error === null;

    if (valid) {
      delete errors[name];
    }

    setErrors({
      ...errors,
      ...(error && (touched[name] || isTouched) ? { [name]: error } : {}),
    });

    if (isTouched && !touched[name]) {
      setTouched({
        ...touched,
        [name]: true,
      });
    }

    return valid;
  };

  const validateMany = async (fields: Field[]) => {
    const result: { errors: IKeyValue, touched: IKeyValue<boolean> } = {
      errors: {},
      touched: {},
    };
    let valid: boolean = fields.length > 0;

    for (const field of fields) {
      const error: string | null = await isValid(field.name, field.value, field.min, field.max);

      if (error === null) {
        delete errors[field.name];
      } else {
        valid = false;
        if (touched[field.name] || field.isTouched) {
          result.errors[field.name] = error;
        }
      }

      if (field.isTouched && !touched[field.name]) {
        result.touched[field.name] = true;
      }
    }

    setErrors({
      ...errors,
      ...result.errors,
    });

    setTouched({
      ...touched,
      ...result.touched,
    });

    return valid;
  };

  const parseOptions = (min: string, max: string) => ({
    min: min ? +min : undefined,
    max: max ? +max : undefined,
  });

  const handleBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, min, max } = e.currentTarget;
    const opts = parseOptions(min, max);
    validate(name, value, opts);
  };

  const handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value, min, max } = e.currentTarget;
    const opts = parseOptions(min, max);
    setTouched({
      ...touched,
      [name]: true,
    });
    const error = isValid(name, value, opts.min, opts.max);
    setErrors({
      ...errors,
      ...() => (error ? { [name]: touched[name] && error } : {}),
    });
  };

  return {
    errors,
    touched,
    handleBlur,
    handleFocus,
    setTouched,
    validate,
    validateMany,
  };
};

export default useValidate;
