import React, { useState } from "react";

import Modal from "ui/Modal";
import { Wrapper, Form, ActionsWrapper } from "./styles";
import Spinner from "ui/Spinner";
import SnackBar from "ui/SnackBar";
import { getErrorMessage } from "utils/parser";
import TextField from "ui/TextField";
import Title from "ui/Title";
import Button from "ui/Button";
import api from "utils/api";
import { IVM } from "types/virtual";
import { useMutation } from "react-query";
import useValidate from "features/tool/hooks/useValidate";

interface IProps {
  vm: IVM;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AssignAddress({ vm, open, setOpen }: IProps) {
  const [ip, setIp] = useState("");
  const {
    errors,
    touched,
    handleBlur,
    handleFocus,
    setTouched,
  } = useValidate();

  const mutation = useMutation(
    () => {
      return api.post(`/vcloud-director/virtual/vms/${vm.id}/assign-ip`, {
        vdcId: vm.vdcId,
        ip,
      });
    },
    {
      onSuccess: () => {
        setOpen(false);
        setIp("");
      },
    }
  );

  const handleChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => {
    setIp(value);
    setTouched({
      ip: true,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (errors && errors.ip) return;
    mutation.mutate();
  };

  const handleCancel = () => {
    setOpen(false);
    setIp("");
  };

  return (
    <>
      <Modal open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
        <Wrapper>
          {mutation.isLoading ? (
            <Spinner height={30} />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Title>Assign IP Address ({vm.name})</Title>
              <TextField
                label="IP Address"
                value={ip}
                onBlur={handleBlur}
                onFocus={handleFocus}
                name="ip"
                error={errors["ip"]}
                touched={touched["ip"]}
                onChange={handleChange}
                required
              />
              <ActionsWrapper>
                <Button variant="outlined" type="submit">
                  Assign
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </ActionsWrapper>
            </Form>
          )}
        </Wrapper>
      </Modal>
      {mutation.isError && (
        <SnackBar message={getErrorMessage(mutation.error)} type="error" />
      )}
    </>
  );
}
