import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
