import React, { useEffect, useState } from "react";
import { useCustomer } from "providers/customer";

import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from "react-query";
import { ActionMeta, OptionTypeBase } from "react-select";
import {
  IOperatingSystem,
  IOperatingSystemsResponse,
  IPlan,
  IVDC,
  IVM,
  IVMForm,
  IVMsTemplate,
} from "types/virtual";
import Button from "ui/Button";
import Label from "ui/Label";
import Modal from "ui/Modal";
import Select from "ui/Select";
import SelectRadio from "ui/SelectRadio";
import SnackBar from "ui/SnackBar";
import Spinner from "ui/Spinner";
import TextField from "ui/TextField";
import api from "utils/api";
import { getErrorMessage, getRecord } from "utils/parser";
import PlanCard from "../PlanCard";
import { plans } from "../PlanCard/config";
import { checkVMQuota, getVMNewParamsFromForm } from "./helper";
import VMsTemplates from "../VMsTemplates";

import {
  Actions,
  CreateFrom,
  CreateFromItem,
  FieldsWrapper,
  PlansWrapper,
  Wrapper,
} from "./styles";

interface IProps {
  open: boolean;
  stats: {
    nbOfVMs: number;
    nbOfCPUs: number;
    maxMemory: number;
    maxStorage: number;
  };
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchVMs: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IVM[], unknown>>;
}

export default function NewVMForm({
  open,
  stats,
  setOpen,
  refetchVMs,
}: IProps) {
  const { customer } = useCustomer();
  const [error, setError] = useState("");

  const { data: vDCs = [], ...vDCsMutation } = useQuery(
    "vDCs",
    async () => {
      if (!customer) return [];
      const response = await api.get("/vcloud-director/query/", {
        params: { type: "orgVdc" },
        headers: {
          "customer-token": customer?.token,
        },
      });
      return getRecord(response) as IVDC[];
    },
    {
      refetchInterval: 5000,
    }
  );

  const { data: operatingSystems = [], ...operatingSystemsMutation } = useQuery(
    "operatingSystems",
    async () => {
      const response = await api.get(
        "/vcloud-director/virtual/operating-systems",
        {
          headers: {
            "customer-token": customer?.token,
          },
        }
      );

      const data = getRecord(response) as IOperatingSystemsResponse;

      const parsedData = data
        .map((one) => {
          return one.operatingSystems.map((os) => ({
            ...os,
            family: one.family,
          }));
        })
        .reduce((acc, cur) => [...acc, ...cur], []);

      return parsedData as IOperatingSystem[];
    },
    {
      refetchOnMount: false,
    }
  );

  const { data: vmsTemplates = [] } = useQuery("vmsTemplates", async () => {
    if (!customer) return [];
    const { data } = await api.get("/vcloud-director/query/", {
      params: {
        type: "vm",
        pageSize: "100",
        sortDesc: "name",
        filter:
          "isVAppTemplate==true;status!=FAILED_CREATION;status!=UNKNOWN;status!=UNRECOGNIZED;status!=UNRESOLVED",
        links: "true",
      },
      headers: {
        "customer-token": customer?.token,
      },
    });
    return data as IVMsTemplate[];
  });

  const initForm: IVMForm = {
    powerOn: true,
    fromTemplate: false,
    vDC: "",
    name: "",
    hostname: "",
    description: "",
    operatingSystem: "",
    plan: plans[1].type,
    template: "",
  };

  const mutation = useMutation(
    () => {
      return api.post(
        "/vcloud-director/virtual/vms",
        getVMNewParamsFromForm({ form, vDCs, operatingSystems, vmsTemplates })
      );
    },
    {
      onSuccess: () => {
        setForm(initForm);
        setOpen(false);
        refetchVMs();
      },
    }
  );

  const [form, setForm] = useState<IVMForm>(initForm);

  const handleChange = ({
    currentTarget: { value, name, type, checked },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: type !== "checkbox" && type !== "radio" ? value : checked,
    });
  };

  const handlePlanClick = ({
    currentTarget: { dataset },
  }: React.FormEvent<HTMLDivElement>) => {
    const type = dataset.type;
    type &&
      setForm({
        ...form,
        plan: type,
      });
  };

  const handleTemplateChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      template: value,
    });
  };

  const handleSelectChange = (
    option: OptionTypeBase | null,
    { name }: ActionMeta<OptionTypeBase>
  ) => {
    if (!option) return;
    option.value && name && setForm({ ...form, [name]: option.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errorMsg = checkVMQuota({ customer, stats, form, vmsTemplates });
    if (errorMsg) {
      setError(errorMsg);
    } else {
      setError("");
      mutation.mutate();
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(""), 5000);
    }
  }, [error]);

  return (
    <Modal open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
      <Wrapper onSubmit={handleSubmit}>
        {mutation.isError && (
          <SnackBar type="error" message={getErrorMessage(mutation.error)} />
        )}
        {error && <SnackBar type="error" message={error} />}
        {mutation.isLoading ? (
          <Spinner width={50} />
        ) : (
          <>
            <FieldsWrapper>
              <Select
                label="Customer"
                name="vDC"
                isDisabled={vDCsMutation.isLoading}
                isLoading={vDCsMutation.isLoading}
                options={vDCs.map((one) => ({
                  label: one.name.split("-").slice(1).join("-"),
                  value: one.name,
                }))}
                value={{
                  label: form.vDC.split("-").slice(1).join("-"),
                  value: form.vDC,
                }}
                placeholder="Customer"
                onChange={handleSelectChange}
              />
              <TextField
                label="VM name"
                name="name"
                value={form.name}
                autoComplete="off"
                required={true}
                onChange={handleChange}
              />
              <TextField
                label="Hostname"
                name="hostname"
                value={form.hostname}
                autoComplete="off"
                required={true}
                onChange={handleChange}
              />
              <TextField
                label="Description"
                name="description"
                value={form.description}
                autoComplete="off"
                required={true}
                onChange={handleChange}
              />
              <CreateFrom>
                <CreateFromItem>
                  <SelectRadio
                    type="radio"
                    name="fromTemplate"
                    id="new"
                    checked={!form.fromTemplate}
                    onChange={() => setForm({ ...form, fromTemplate: false })}
                  />
                  <Label htmlFor="new">New </Label>
                </CreateFromItem>
                <CreateFromItem>
                  <SelectRadio
                    type="radio"
                    name="fromTemplate"
                    id="template"
                    checked={form.fromTemplate}
                    onChange={() => setForm({ ...form, fromTemplate: true })}
                  />
                  <Label htmlFor="template">From Template</Label>
                </CreateFromItem>
              </CreateFrom>
              {!form.fromTemplate ? (
                <>
                  <Select
                    label="Operating System"
                    name="operatingSystem"
                    isDisabled={operatingSystemsMutation.isLoading}
                    isLoading={operatingSystemsMutation.isLoading}
                    value={{
                      label: form.operatingSystem,
                      value: form.operatingSystem,
                    }}
                    options={operatingSystems.map((one) => ({
                      label: one.os,
                      value: one.os,
                    }))}
                    onChange={handleSelectChange}
                  />
                  <PlansWrapper>
                    {plans.map((plan: IPlan) => (
                      <PlanCard
                        key={plan.type}
                        isSelected={form.plan === plan.type}
                        handleClick={handlePlanClick}
                        {...plan}
                      />
                    ))}
                  </PlansWrapper>
                </>
              ) : (
                <>
                  <VMsTemplates
                    vmsTemplates={vmsTemplates}
                    handleChange={handleTemplateChange}
                  />
                </>
              )}
            </FieldsWrapper>
            <Actions>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </Button>
              <Button variant="outlined" type="submit">
                Create
              </Button>
            </Actions>
          </>
        )}
      </Wrapper>
    </Modal>
  );
}
