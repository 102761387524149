import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
  min-height: 30rem;
  flex: 1;
  padding: 3rem 3rem 7rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.6rem;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  & > button {
    margin: auto 0.2rem;
  }
`;
