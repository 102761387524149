import React from "react";

type CodeProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function Code({
  fill = "#000",
  width = 20,
  height = 20,
}: CodeProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18.906 12.31"
    >
      <g id="code" transform="translate(0 -82.219)">
        <g id="Group_159" data-name="Group 159" transform="translate(0 82.219)">
          <path
            id="Path_128"
            data-name="Path 128"
            d="M190.636,352.7a.355.355,0,0,0-.263-.1H179.379a.353.353,0,0,0-.366.366v.733a.353.353,0,0,0,.366.366h10.993a.353.353,0,0,0,.366-.366v-.733A.354.354,0,0,0,190.636,352.7Z"
            transform="translate(-171.833 -341.756)"
            fill={fill}
          />
          <path
            id="Path_129"
            data-name="Path 129"
            d="M1.214,82.334a.36.36,0,0,0-.527,0l-.573.573a.36.36,0,0,0,0,.527l4.5,4.5-4.5,4.5a.36.36,0,0,0,0,.527l.573.572a.36.36,0,0,0,.527,0L6.55,88.2a.36.36,0,0,0,0-.527Z"
            transform="translate(0 -82.219)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}
