import CustomerForm from "features/tool/components/CustomerForm";
import Layout from "features/tool/components/Layout";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useMutation } from "react-query";
import { ICustomerForm, ILicense } from "types/tool";
import Button from "ui/Button";
import api from "utils/api";
import { getRecord } from "utils/parser";

export default function CreateCustomer() {
  const initForm: ICustomerForm = {
    customerID: "",
    msp: "",
    orgPrefix: "",
    address: "",
    phone: "",
    orgName: "",
    expirationDate: "",
    username: "",
    password: "",
    email: "",
    defaultDataCenter: "",
    resources: [],
    isUploaded: false,
    isActive: false,
  };

  const [form, setForm] = useState<ICustomerForm>(initForm);

  const submitMutation = useMutation(
    async () => {
      const response = await api.post(`/tool/customers`, form);
      return getRecord(response) as ILicense;
    },
    {
      onSuccess: ({ secretKey, ...license }) => {
        const blobLicense = new Blob([JSON.stringify(license, null, 2)], {
          type: "text/plain;charset=utf-8",
        });
        const blobPassword = new Blob([JSON.stringify({ secretKey })], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blobLicense, `${license.licenseInfo.orgName}-license.json`);
        saveAs(blobPassword, `${license.licenseInfo.orgName}-password.json`);
        setForm(initForm);
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitMutation.mutate();
  };

  return (
    <Layout>
      <CustomerForm
        initForm={initForm}
        form={form}
        setForm={setForm}
        handleSubmit={handleSubmit}
      >
        <Button type="submit">
          <FaUser /> Create & Generate license
        </Button>
      </CustomerForm>
    </Layout>
  );
}
