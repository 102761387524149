import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { slideInUp, slideOutDown } from 'theme/keyframes';

interface IWrapperProps {
  isVisible?: boolean;
}

export const SlideAnimationDuration = 0.4; // seconds

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 10%;
  width: 80%;
  background-color: #fff;
  z-index: 1000;
  animation: ${(props) =>
    props.isVisible
      ? css`
          ${SlideAnimationDuration}s ${slideInUp}
        `
      : css`
          ${SlideAnimationDuration}s ${slideOutDown} forwards
        `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4472c4;
  padding: 6px 10px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #ddd;
  border-top: none;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  padding: 20px 35px 20px 15px;
`;

export const VMHeadWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  > span {
    margin-left: 10px;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #000;
  }
`;

export const VMPictureWrapper = styled.div`
  cursor: pointer;
`;

export const VMPicture = styled.img`
  width: 70px;
  height: 40px;
`;

export const EmptyVMPictureWrapper = styled.div`
  width: 70px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const VMActionsWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
`;

export const VMActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 18px;
  background-color: #fff;
  border: 1px solid #ddd;
  font-weight: normal;
  font-size: 10px;
  text-align: left;
  color: #000;
  cursor: pointer;
  > svg {
    color: #4472c4;
    margin-right: 5px;
  }
`;

export const VMStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  > div {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #888;
    margin-bottom: 10px;
    > span {
      color: #000;
      &.red {
        color: #be0000;
      }
    }
    > a {
      color: #4472c4;
      font-size: 12px;
      text-decoration: underline;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 15px;
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  flex-grow: unset;
  flex-shrink: unset;
  justify-content: center;
`;

export const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #fff;
  > span {
    font-weight: normal;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border: none;
  outline: none;
  background-color: #4472c4;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.04em;
  text-align: center;
  border-radius: 4px;
`;


export const Mount = styled.div`
  &:hover {
    color: #4472c4;
    cursor: pointer;
  }
`

export const MountWrapper = styled.div`
  cursor: pointer;
  span {
      color: #000;
      &.red {
        color: #be0000;
      }
    }
`;
