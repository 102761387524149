import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.error.light};
  color: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
  padding: ${(props) => props.theme.spacing.md};
`;
