import React, { useCallback, useState } from "react";
import {
  FaCheckCircle,
  FaCloudUploadAlt,
  FaEye,
  FaTimesCircle,
  FaToggleOn,
} from "react-icons/fa";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import Layout from "features/tool/components/Layout";
import { ICustomer, ICustomerResource } from "types/tool";
import api from "utils/api";
import { getRecord } from "utils/parser";
import {
  CellRows,
  CellRow,
  IsUploadedWrapper,
  IsExpiredWrapper,
  IIsActiveWrapper,
  PasswordWrapper,
  ActionsWrapper,
  PasswordUploadWrapper,
  PasswordUploadTitle,
  PasswordUploadMessage,
  PasswordUploadSubtitle,
  DecryptedPasswordWrapper,
} from "./styles";

import DataTable from "ui/DataTable";
import { AxiosResponse } from "axios";
import Button from "ui/Button";
import Modal from "ui/Modal";
import { useDropzone } from "react-dropzone";
import { decrypt } from "utils/tool";

const IsUploaded = (row: ICustomer) => (
  <IsUploadedWrapper isActive={row.isUploaded}>
    {row.isUploaded ? <FaCheckCircle /> : <FaTimesCircle />}
  </IsUploadedWrapper>
);

const IsExpired = (row: ICustomer) => {
  const isExpired = Date.parse(row.expirationDate) < Date.now();
  return (
    <IsExpiredWrapper isExpired={isExpired}>
      <span>{isExpired ? "Expired" : "Valid"}</span>
    </IsExpiredWrapper>
  );
};

const IsActive = (
  row: ICustomer,
  mutation: UseMutationResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any>,
    unknown,
    {
      id: string;
      isActive: boolean;
    },
    unknown
  >
) => {
  return (
    <IIsActiveWrapper
      isActive={row.isActive}
      onClick={() => mutation.mutate({ id: row._id, isActive: row.isActive })}
    >
      <FaToggleOn />
    </IIsActiveWrapper>
  );
};

interface IPasswordModal {
  selectedPasswordRow: ICustomer;
  setSelectedPasswordRow: React.Dispatch<
    React.SetStateAction<ICustomer | null>
  >;
}

const PasswordModal = ({
  selectedPasswordRow,
  setSelectedPasswordRow,
}: IPasswordModal) => {
  const [decryptedPassword, setDecryptedPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        const parsedResult =
          typeof reader.result === "string" ? JSON.parse(reader.result) : {};
        if (parsedResult.secretKey) {
          const decryptedPassword = decrypt(
            selectedPasswordRow.password,
            parsedResult.secretKey
          );
          if (decryptedPassword) {
            setDecryptedPassword(decryptedPassword);
            setIsError(false);
          } else {
            setIsError(true);
          }
        }
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <Modal
      open={Boolean(selectedPasswordRow)}
      closeOnDocumentClick
      onClose={() => setSelectedPasswordRow(null)}
    >
      <PasswordWrapper>
        <PasswordUploadTitle>Get password</PasswordUploadTitle>
        <PasswordUploadSubtitle isError={isError}>
          {decryptedPassword && "Click to copy the password"}
          {isError && "Secret key is invalid"}
          {!decryptedPassword && !isError && "Click to copy the password"}
        </PasswordUploadSubtitle>
        {decryptedPassword ? (
          <DecryptedPasswordWrapper>
            {decryptedPassword}
          </DecryptedPasswordWrapper>
        ) : (
          <PasswordUploadWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <FaCloudUploadAlt />
            <PasswordUploadMessage>
              Drag your file or click to upload
            </PasswordUploadMessage>
          </PasswordUploadWrapper>
        )}
        <ActionsWrapper>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setSelectedPasswordRow(null)}
          >
            Cancel
          </Button>
        </ActionsWrapper>
      </PasswordWrapper>
    </Modal>
  );
};

interface IPassword {
  row: ICustomer;
  setSelectedPasswordRow: React.Dispatch<
    React.SetStateAction<ICustomer | null>
  >;
}

const Password = ({ row, setSelectedPasswordRow }: IPassword) => {
  return (
    <>
      <Button variant="link" onClick={() => setSelectedPasswordRow(row)}>
        <FaEye />
      </Button>
    </>
  );
};

export default function Customers() {
  const history = useHistory();
  const [
    selectedPasswordRow,
    setSelectedPasswordRow,
  ] = useState<ICustomer | null>(null);

  const { data: customers = [], isLoading, refetch } = useQuery(
    "customers",
    async () => {
      const response = await api.get("/tool/customers");
      return getRecord(response) as ICustomer[];
    }
  );

  const isActiveMutation = useMutation(
    async ({ id, isActive }: { id: string; isActive: boolean }) => {
      return api.put(`/tool/customers/${id}/is-active`, {
        isActive: !isActive,
      });
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleRowClick = (id: string) => {
    history.push(`/tool/customers/update/${id}`);
  };

  const defaultCellRender = (
    row: ICustomer,
    value: React.ReactNode,
    shouldHandleClick = true
  ) => {
    return (
      <CellRows
        shouldHandleClick={shouldHandleClick}
        onClick={() => shouldHandleClick && handleRowClick(row._id)}
      >
        {row.resources.map((one, index) => (
          <CellRow key={one.dataCenter}>{!index && value}</CellRow>
        ))}
        <CellRow />
      </CellRows>
    );
  };

  const resourcesCellRender = (
    row: ICustomer,
    key: keyof ICustomerResource,
    shouldHandleClick = true
  ) => {
    return (
      <CellRows
        shouldHandleClick={shouldHandleClick}
        onClick={() => shouldHandleClick && handleRowClick(row._id)}
      >
        <CellRow>
          {key !== "dataCenter"
            ? row.resources.reduce((acc, cur) => acc + Number(cur[key]), 0)
            : "Total"}
        </CellRow>
        {row.resources.map((one) => (
          <CellRow key={one.dataCenter}>{one[key]}</CellRow>
        ))}
      </CellRows>
    );
  };

  const columns = [
    {
      name: "Organization",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.orgName),
    },
    {
      name: "MSP name",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.msp),
    },
    {
      name: "Default Data center",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.defaultDataCenter),
    },
    {
      name: "Data center",
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "dataCenter"),
    },
    {
      name: "Number of VMs",
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "nbOfVMs"),
    },
    {
      name: "Number of CPUs",
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "nbOfCPUs"),
    },
    {
      name: "Max Memory (GB)",
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "maxMemory"),
    },
    {
      name: "Max Storage (GB)",
      center: true,
      compact: true,
      cell: (row: ICustomer) => resourcesCellRender(row, "maxStorage"),
    },
    {
      name: "Expiration Date",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, row.expirationDate),
    },
    {
      name: "Activated",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, IsUploaded(row)),
    },
    {
      name: "Expiration status",
      compact: true,
      cell: (row: ICustomer) => defaultCellRender(row, IsExpired(row)),
    },
    {
      name: "Activate / De-activate",
      compact: true,
      cell: (row: ICustomer) =>
        defaultCellRender(row, IsActive(row, isActiveMutation), false),
    },
    {
      name: "Password",
      compact: true,
      cell: (row: ICustomer) =>
        defaultCellRender(
          row,
          <Password
            row={row}
            setSelectedPasswordRow={setSelectedPasswordRow}
          />,
          false
        ),
    },
  ];

  return (
    <Layout>
      <DataTable
        columns={columns}
        data={customers}
        progressPending={isLoading}
        noHeader
        pointerOnHover={true}
        highlightOnHover={true}
        pagination
        paginationTopRight
        onRowClicked={(row) => {
          history.push(`/tool/customers/update/${row._id}`);
        }}
        searchField="orgName"
      />
      {selectedPasswordRow && (
        <PasswordModal
          selectedPasswordRow={selectedPasswordRow}
          setSelectedPasswordRow={setSelectedPasswordRow}
        />
      )}
    </Layout>
  );
}
