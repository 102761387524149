import styled from "@emotion/styled";

export const TextAreaWrapper = styled.textarea`
    border: 1px solid ${props => props.theme.palette.divider}; 
    min-height: 8rem;
    padding: 1rem;
    width: 100%;
    &:focus {
        outline: none;
    }
    &:hover {
        outline: none;
    }
`