import React, { CSSProperties } from "react";
import Popup from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";

const overlayStyle = { background: "rgba(0,0,0,0.5)", zIndex: 1000 };

const contentStyle: CSSProperties = {
  border: "1px solid #666",
  background: "#FFF",
  borderRadius: "1rem",
  maxHeight: "90vh",
  overflowY: "auto",
};

interface IProps extends PopupProps, React.RefAttributes<PopupActions> {}

export default function Modal({ children, ...rest }: IProps) {
  return (
    <Popup
      position="center center"
      {...{ overlayStyle, contentStyle }}
      {...rest}
    >
      {children}
    </Popup>
  );
}
