import React from "react";
import { FaToggleOn } from "react-icons/fa";
import { Wrapper, ToggleWrapper, Label } from "./styles";

interface IProps {
  isOn: boolean;
  label?: string;
  onClick?: () => void;
}

export default function Toggle({ label, isOn, onClick }: IProps) {
  return (
    <Wrapper onClick={onClick}>
      <Label>{label}</Label>
      <ToggleWrapper isOn={isOn}>
        <FaToggleOn />
      </ToggleWrapper>
    </Wrapper>
  );
}
