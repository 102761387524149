import styled from "@emotion/styled";
type IProps = {
  color?: "primary" | "secondary";
};
export default styled.label<IProps>`
  display: block;
  color: ${({ theme, color = "secondary" }) =>
    color == "secondary"
      ? theme.palette.gray.main
      : theme.palette.primary.main};
  margin-bottom: 0.5rem;
`;
