import styled from "@emotion/styled";

type WrapperProps = {
  isSelected: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid ${({ theme, isSelected }) => isSelected ? theme.palette.primary.main : theme.palette.divider};
  border-radius: 2rem;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  width: 15rem;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Body = styled.div`
  padding: 1rem;
`;

export const SpecText = styled.p``;
