import React from "react";
import queryString from "query-string";

import CardsWrapper from "features/virtual/components/CardsWrapper";
import VMCard from "features/virtual/components/VMCard";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { IVM } from "types/virtual";
import ErrorMsg from "ui/ErrorMsg";
import Spinner from "ui/Spinner";
import api from "utils/api";
import { getErrorMessage } from "utils/parser";
import { Wrapper } from "./styles";
import { useCustomer } from "providers/customer";

interface ICreateFilter {
  vm?: string;
  customer?: string;
  vApp?: string;
}

const createFilter = (params: ICreateFilter) => {
  let filter = "";
  if (params.vm) filter = `id==${params.vm}`;
  if (params.customer) filter = `vdc==${params.customer}`;
  if (params.vApp)
    filter =
      params.vApp.toLowerCase() === "standalone"
        ? ""
        : `containerName==${params.vApp}`;

  return filter
    ? `(((isExpired==false;isVAppTemplate==false));((${filter})))`
    : `(((isExpired==false;isVAppTemplate==false)))`;
};

export default function VMs() {
  const location = useLocation();
  const { customer } = useCustomer();
  const params = queryString.parse(location.search);

  const filter = createFilter(params);

  const { isLoading, error, data: vms = [], refetch } = useQuery(
    `vms-${location.search}`,
    async () => {
      if (!customer) return [];
      const { data } = await api.get("/vcloud-director/query/", {
        params: {
          type: "vm",
          pageSize: 100,
          format: "records",
          filterEncoded: true,
          filter: filter,
          links: true,
        },
        headers: {
          "customer-token": customer?.token,
        },
      });
      return data as IVM[];
    },
    {
      refetchInterval: 5000,
    }
  );

  if (isLoading) return <Spinner marginTop={100} />;

  if (error) return <ErrorMsg message={getErrorMessage(error)} />;

  return (
    <Wrapper>
      <CardsWrapper>
        {vms.map((vm) => (
          <VMCard key={vm.id} vm={vm} refetch={refetch} />
        ))}
      </CardsWrapper>
    </Wrapper>
  );
}
