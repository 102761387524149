import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router";
import { ICustomer } from "types/tool";
import GlobalLayout from "ui/GlobalLayout";
import api from "utils/api";


interface ICustomerContext {
  customer: ICustomer | null;
}

const CustomerContext = React.createContext({} as ICustomerContext);

interface ICustomerDispatchContext {
  setCustomer: React.Dispatch<React.SetStateAction<ICustomer | null>>;
  removeCustomer: () => void;
}

const CustomerDispatchContext = React.createContext(
  {} as ICustomerDispatchContext
);

interface IProps {
  children: React.ReactNode;
}

export default function CustomerProvider({ children }: IProps) {
  const history = useHistory();
  const location = useLocation();

  const init: ICustomerContext = {
    customer: null,
  };

  const token = localStorage.getItem("token");

  const [customer, setCustomer] = useState(init.customer);

  const removeCustomer = () => {
    setCustomer(null);
  };

  const mutation = useMutation(
    async () => api.post("/tool/authenticate", { token }),
    {
      onSuccess: ({ data }) => {
        setCustomer({ ...data, token });
      },
      onError: () => {
        history.push("/tool/licenses/upload");
      },
    }
  );

  useEffect(() => {
    const allowedPaths = [
      "/tool/customers/create",
      "/tool/customers/update",
      "/tool/settings/datacenters",
      "/tool/api",
      "/tool/notifications",
      "/tool/login",
      "/tool/register",
      "/tool/2fa/verify",
      "/tool/2fa/setup",
    ];
    if (allowedPaths.find((one) => location.pathname.includes(one)))
    {
      return;
    } else if (token)
    {
      mutation.mutate();
    } else
    {
      history.push("/tool/licenses/upload");
    }
  }, [token]);

  return (
    <CustomerDispatchContext.Provider value={{ setCustomer, removeCustomer }}>
      <CustomerContext.Provider value={{ customer }}>
        <GlobalLayout>{children}</GlobalLayout>
      </CustomerContext.Provider>
    </CustomerDispatchContext.Provider>
  );
}

export const useCustomer = () => React.useContext(CustomerContext);
export const useDispatchCustomer = () =>
  React.useContext(CustomerDispatchContext);
