import ScoCloudSvg from 'assets/svg/SCOCloudSVG';
import useValidate from 'features/tool/hooks/useValidate';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Button from 'ui/Button';
import Label from 'ui/Label';
import Input from 'ui/TextField';
import api from 'utils/api';
import { FormWrapper, Image, Instruction, Row, Wrapper } from './styles';

type Form = {
  token: string;
};

export default function Setup() {
  const [form, setForm] = React.useState<Form>({ token: '' });
  const timestamp = React.useRef(new Date().getTime());
  const { push } = useHistory();

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const [verificationError, setVerificationError] = useState<string>();

  const mutation = useMutation(
    async () => api.post('/challenges/otp/verify', form),
    {
      onSuccess: ({ data }) => {
        if (data.success) push('/tool/customers/create');
        else setVerificationError('Invalid Code !');
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  const {
    errors,
    handleBlur,
    handleFocus,
    setTouched,
    touched,
  } = useValidate();

  return (
    <Wrapper>
      <ScoCloudSvg />
      <Row>
        <Instruction>
          <strong>1.</strong>Dowload and Install a supported TOTP Athenticator
          App
        </Instruction>
        <Instruction>
          <strong>2.</strong>Open the app
        </Instruction>
        <Instruction>
          <strong>3.</strong>Pair your app by scanning the QR Code below
        </Instruction>
      </Row>
      <Row>
        <Image
          src={`/api/v1/challenges/otp/setup/?$qr=true&$format=otpauth_url&t=${timestamp.current}`}
        />
        <FormWrapper onSubmit={handleSubmit}>
          <Label>Token</Label>
          <Input
            name="token"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors['token'] || verificationError}
            touched={touched['token']}
            required
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={mutation.isError}
          >
            {mutation.isLoading ? (
              <Loader height={15} width={15} type="TailSpin" color="#FFF" />
            ) : (
              'Verify'
            )}
          </Button>
        </FormWrapper>
      </Row>
    </Wrapper>
  );
}
