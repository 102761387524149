import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 80%;
  margin: 10rem auto;
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  & > button {
    margin-left: 1rem;
  }
`;

export const ActionsWrapper = styled.div``;
