import React, { useState } from "react";
import { QueryObserverResult, RefetchOptions, useMutation } from "react-query";
import {
  FaArrowCircleDown,
  FaCopy,
  FaHdd,
  FaNetworkWired,
  FaPowerOff,
  FaRecycle,
  FaTrash,
} from "react-icons/fa";

import ConsoleSvg from "assets/svg/Code";
import CpuSvg from "assets/svg/CPU";
import NetworkSvg from "assets/svg/Network";
import RAMSvg from "assets/svg/RAM";

import {
  Card,
  CardActions,
  CardHeader,
  CardInfo,
  CardRow,
  CardRows,
  CardSpec,
  CardTitle,
} from "features/virtual/components/Card";
import { IHardDisk, IVM } from "types/virtual";
import { DropDownItem } from "ui/DropDown";
import SnackBar from "ui/SnackBar";
import api from "utils/api";
import { getErrorMessage, getRecord } from "utils/parser";
import Breadcrumbs from "../Breadcrumbs";
import HardDisksModal from "../HardDisks";
import { Console } from "./styles";
import CardStatus from "../CardStatus";
import AssignAddressModal from "../AssignAddress";

type IActionsTypes =
  | "power-on"
  | "power-off"
  | "delete"
  | "create-snapshot"
  | "revert-snapshot"
  | "install-vmware-tools"
  | "remove-all-snapshots";

interface IProps {
  vm: IVM;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IVM[], unknown>>;
}

export default function VMCard({ vm, refetch }: IProps) {
  const {
    name,
    containerName,
    status,
    memoryMB,
    networkName,
    numberOfCpus,
    snapshot,
    vmToolsStatus,
    isAutoNature,
    id,
    isBusy,
  } = vm;

  const handleConsole = () => {
    window.open(
      `/api/v1/vcloud-director/virtual/vms/${id}/console`,
      "_blank",
      ""
    );
  };

  const [openHardDisksModal, setOpenHardDisksModal] = useState(false);

  const [openAssignAddressModal, setOpenAssignAddressModal] = useState(false);

  const actionsMutation = useMutation(
    ({ id, type }: { id: string; type: IActionsTypes }) => {
      return api.post(`/vcloud-director/virtual/action/${type}`, {
        vmId: id,
      });
    },
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const hardDisksMutation = useMutation(
    async ({ id }: { id: string }) => {
      const response = await api.get(
        `/vcloud-director/virtual/vms/${id}/hard-disks`
      );
      return getRecord(response) as IHardDisk[];
    },
    {
      onSettled: () => {
        refetch();
      },
    }
  );

  const handlePowerOn = () => {
    actionsMutation.mutate({ id, type: "power-on" });
  };

  const handlePowerOff = () => {
    actionsMutation.mutate({ id, type: "power-off" });
  };

  const handleInstallVMwareTools = () => {
    actionsMutation.mutate({ id, type: "install-vmware-tools" });
  };

  const handleDelete = () => {
    actionsMutation.mutate({ id, type: "delete" });
  };

  const handleCreateSnapshot = () => {
    actionsMutation.mutate({ id, type: "create-snapshot" });
  };

  const handleRevertSnapshot = () => {
    actionsMutation.mutate({ id, type: "revert-snapshot" });
  };

  const handleRemoveSnapshot = () => {
    actionsMutation.mutate({ id, type: "remove-all-snapshots" });
  };

  const handleOpenHardDisksModal = () => {
    setOpenHardDisksModal(true);
    hardDisksMutation.mutate({ id });
  };

  const handleOpenAssignAddressModal = () => {
    setOpenAssignAddressModal(true);
  };

  const Actions = () => (
    <>
      <DropDownItem
        onClick={handlePowerOn}
        isDisabled={status === "POWERED_ON"}
      >
        <FaPowerOff />
        Power On
      </DropDownItem>
      <DropDownItem
        onClick={handlePowerOff}
        isDisabled={status === "POWERED_OFF"}
      >
        <FaPowerOff />
        Power Off
      </DropDownItem>
      <DropDownItem onClick={handleDelete} isDisabled={status === "POWERED_ON"}>
        <FaTrash />
        Delete
      </DropDownItem>
      {vmToolsStatus === "guestToolsNotInstalled" && (
        <DropDownItem onClick={handleInstallVMwareTools}>
          <FaArrowCircleDown />
          Install VMware tools
        </DropDownItem>
      )}
      <DropDownItem onClick={handleCreateSnapshot}>
        <FaCopy />
        Create Snapshot
      </DropDownItem>
      <DropDownItem onClick={handleRevertSnapshot}>
        <FaRecycle />
        Revert Snapshot
      </DropDownItem>
      <DropDownItem onClick={handleRemoveSnapshot}>
        <FaTrash />
        Remove Snapshot
      </DropDownItem>
      <DropDownItem onClick={handleOpenHardDisksModal}>
        <FaHdd />
        Hard disks
      </DropDownItem>
      <DropDownItem
        onClick={handleOpenAssignAddressModal}
        isDisabled={status === "POWERED_ON"}
      >
        <FaNetworkWired />
        Assign IP Address
      </DropDownItem>
    </>
  );

  return (
    <>
      <Card>
        <CardHeader isVApp={false}>
          {actionsMutation.isError && (
            <SnackBar
              message={getErrorMessage(actionsMutation.error)}
              type="error"
            />
          )}
          {hardDisksMutation.isError && (
            <SnackBar
              message={getErrorMessage(hardDisksMutation.error)}
              type="error"
            />
          )}
          <Breadcrumbs
            containerName={containerName}
            name={name}
            isAutoNature={isAutoNature}
          />
          <CardActions
            id={id}
            isVApp={false}
            status={status}
            actions={<Actions />}
          >
            <Console onClick={handleConsole}>
              <ConsoleSvg />
            </Console>
          </CardActions>
        </CardHeader>
        <CardRows>
          <CardRow>
            <CardTitle isVApp={false} title={name} />
            <CardStatus status={status} isBusy={isBusy} />
          </CardRow>
          <CardRow withBorder>
            <CardSpec Icon={CpuSvg} value={numberOfCpus} desc="CPUs" />
            <CardSpec Icon={RAMSvg} value={`${memoryMB} MB`} desc="Memory" />
            <CardSpec
              Icon={NetworkSvg}
              value={networkName || ""}
              desc="Network Name"
            />
          </CardRow>
          <CardRow>
            <CardInfo name="Lease" value="Never Expires" />
            <CardInfo
              name="VMware Tools"
              value={
                vmToolsStatus &&
                vmToolsStatus.toLowerCase().includes("notinstalled")
                  ? "-"
                  : "Yes"
              }
            />
            <CardInfo name="Snapshot" value={snapshot ? "Yes" : "-"} />
          </CardRow>
        </CardRows>
      </Card>

      <HardDisksModal
        vm={vm}
        hardDisks={hardDisksMutation.data || []}
        isLoading={hardDisksMutation.isLoading}
        open={openHardDisksModal}
        setOpen={setOpenHardDisksModal}
      />

      <AssignAddressModal
        vm={vm}
        open={openAssignAddressModal}
        setOpen={setOpenAssignAddressModal}
      />
    </>
  );
}
