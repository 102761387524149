import React from "react";
import { Global, css } from "@emotion/react";

const styles = css`
  html {
    font-size: 10px;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
  }
  body {
    font-size: 1.2rem;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
`;

export default function GlobalStyles() {
  return <Global styles={styles} />;
}
