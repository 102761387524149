import ColumnsSelector from "features/dashboard/components/ColumnsSelector";
import NewCustomerForm from "features/dashboard/components/NewCustomerForm";
import NewVMForm from "features/dashboard/components/NewVMForm";
import StatsCircle from "features/dashboard/components/StatsCircle";
import VMDetails from "features/dashboard/components/VMDetails";
import { generateDashboardData } from "features/dashboard/helpers/data";
import CardStatus from "features/virtual/components/CardStatus";
import { useCustomer } from "providers/customer";
import React, { useState } from "react";
import { IDataTableColumn } from "react-data-table-component";
import { FaBars, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { ActionMeta, OptionTypeBase } from "react-select";
import { IVMRow } from "types/dashboard";
import { IVM } from "types/virtual";
import Button from "ui/Button";
import DataTable from "ui/DataTable";
import Select from "ui/Select";
import api from "utils/api";
import {
  Cell,
  CellLink,
  DataTableHeaderWrapper,
  StatsCirclesWrapper,
  Wrapper,
} from "./styles";

interface IDataTableHeaderProps {
  setIsColumnsSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewCustomerFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNewVMFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataTableHeader = ({
  setIsColumnsSelectorOpen,
  setIsNewCustomerFormOpen,
  setIsNewVMFormOpen,
}: IDataTableHeaderProps) => {
  return (
    <DataTableHeaderWrapper>
      <Button color="default" onClick={() => setIsColumnsSelectorOpen(true)}>
        <FaBars />
        Columns selector
      </Button>
      <Button color="default" onClick={() => setIsNewCustomerFormOpen(true)}>
        <FaPlus /> Add Customer
      </Button>
      <Button color="default" onClick={() => setIsNewVMFormOpen(true)}>
        <FaPlus /> Add VM
      </Button>
    </DataTableHeaderWrapper>
  );
};

export default function Dashboard() {
  const [isColumnsSelectorOpen, setIsColumnsSelectorOpen] = useState(false);
  const [isNewCustomerFormOpen, setIsNewCustomerFormOpen] = useState(false);
  const [isNewVMFormOpen, setIsNewVMFormOpen] = useState(false);
  const { customer } = useCustomer();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [currentVMRowId, setCurrentVMRowId] = useState("");
  const history = useHistory();

  const { data: vms = [], isLoading, refetch } = useQuery(
    "vms",
    async () => {
      const { data } = await api.get("/vcloud-director/query/", {
        params: {
          type: "vm",
          pageSize: 100,
          format: "records",
          filterEncoded: true,
          filter: "(((isExpired==false));((isVAppTemplate==false)))",
          links: true,
        },
        headers: {
          "customer-token": customer?.token,
        },
      });
      return data as IVM[];
    },
    { refetchInterval: 10000 }
  );

  const handleRowClick = (row: IVMRow) => {
    setCurrentVMRowId(row.id);
    setIsDetailsOpen(true);
  };

  interface IMapCellTypeToKey {
    vm: "id";
    customer: "vdcId";
    vApp: "vApp";
  }

  const handleCellClick = (row: IVMRow, type: "vm" | "customer" | "vApp") => {
    const mapTypeToKey: IMapCellTypeToKey = {
      vm: "id",
      customer: "vdcId",
      vApp: "vApp",
    };
    const key = mapTypeToKey[type];
    return history.push({ pathname: "/vms", search: `?${type}=${row[key]}` });
  };

  const initColumns: IDataTableColumn<IVMRow>[] = React.useMemo(
    function getColumns() {
      return [
        {
          id: 1,
          name: "VM",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return (
              <CellLink onClick={() => handleCellClick(row, "vm")}>
                {row.name}
              </CellLink>
            );
          },
        },
        {
          id: 2,
          name: "Customer",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return (
              <CellLink onClick={() => handleCellClick(row, "customer")}>
                {row.vdcName.split("-").slice(1).join("-")}
              </CellLink>
            );
          },
        },
        {
          id: 3,
          name: "vApp",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return (
              <CellLink onClick={() => handleCellClick(row, "vApp")}>
                {row.vApp}
              </CellLink>
            );
          },
        },
        {
          id: 4,
          name: "DC",
          sortable: true,
          omit: false,
          cell: function custom() {
            return <Cell>XANY</Cell>;
          },
        },
        {
          id: 5,
          name: "Alarm",
          sortable: true,
          omit: false,
          cell: function custom() {
            return <Cell>Yes</Cell>;
          },
        },
        {
          id: 6,
          name: "State",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return <CardStatus status={row.status} isBusy={row.isBusy} />;
          },
        },
        {
          id: 7,
          name: "OS",
          selector: "guestOs",
          sortable: true,
          omit: false,
        },
        {
          id: 8,
          name: "CPU",
          selector: "numberOfCpus",
          sortable: true,
          omit: false,
        },
        {
          id: 9,
          name: "RAM(GB)",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return <Cell>{Math.floor(row.memoryMB / 1024)}</Cell>;
          },
        },
        {
          id: 10,
          name: "Storage(GB)",
          sortable: true,
          omit: false,
          cell: function custom(row: IVMRow) {
            return (
              <Cell>{Math.floor(row.totalStorageAllocatedMb / 1024)}</Cell>
            );
          },
        },
        {
          id: 13,
          name: "IP Add",
          selector: "ipAddress",
          sortable: true,
          omit: false,
        },
        {
          id: 11,
          name: "WAN IP",
          selector: "wanip",
          sortable: true,
          omit: true,
        },
        {
          id: 12,
          name: "IN > Ports",
          selector: "inports",
          sortable: true,
          omit: true,
        },
        {
          id: 14,
          name: "Last backup",
          sortable: true,
          omit: true,
          cell: function custom() {
            return <Cell />;
          },
        },
        {
          id: 15,
          name: "Last replicant",
          sortable: true,
          omit: true,
          cell: function custom() {
            return <Cell />;
          },
        },
      ];
    },
    []
  );

  const [columns, setColumns] = useState(initColumns);

  const resource = customer?.resources.find(
    (one) => one.dataCenter === customer.defaultDataCenter
  );

  const stats = vms.reduce(
    (acc, cur) => ({
      ...acc,
      nbOfVMs: acc.nbOfVMs + 1,
      nbOfCPUs: acc.nbOfCPUs + cur.numberOfCpus,
      maxMemory: acc.maxMemory + Math.floor(cur.memoryMB / 1024),
      maxStorage:
        acc.maxStorage + Math.floor(cur.totalStorageAllocatedMb / 1024),
    }),
    { nbOfVMs: 0, nbOfCPUs: 0, maxMemory: 0, maxStorage: 0 }
  );

  const selectedVM = vms.find((vm) => vm.id === currentVMRowId);

  const [currentDC, setCurrentDC] = useState(customer?.defaultDataCenter || "");

  const handleSelectChange = (
    option: OptionTypeBase | null,
    /*eslint-disable-next-line */
    _: ActionMeta<OptionTypeBase>
  ) => {
    if (!option) return;
    setCurrentDC(option.value);
  };

  return (
    <>
      <Wrapper>
        <Select
          label="Data center"
          options={customer?.resources.map((one) => ({
            label: one.dataCenter,
            value: one.dataCenter,
          }))}
          value={{ label: currentDC, value: currentDC }}
          width="20rem"
          onChange={handleSelectChange}
        />
        <StatsCirclesWrapper>
          <StatsCircle
            type="VMs"
            used={stats.nbOfVMs}
            total={resource?.nbOfVMs}
          />
          <StatsCircle
            type="CPUs"
            used={stats.nbOfCPUs}
            total={resource?.nbOfCPUs}
          />
          <StatsCircle
            type="RAM"
            used={stats.maxMemory}
            total={resource?.maxMemory}
          />
          <StatsCircle
            type="Storage"
            used={stats.maxStorage}
            total={resource?.maxStorage}
          />
        </StatsCirclesWrapper>

        <DataTable
          columns={columns}
          data={generateDashboardData(vms)}
          pagination
          paginationTopRight
          searchField="name"
          progressPending={isLoading}
          headerChildren={
            <DataTableHeader
              setIsColumnsSelectorOpen={setIsColumnsSelectorOpen}
              setIsNewCustomerFormOpen={setIsNewCustomerFormOpen}
              setIsNewVMFormOpen={setIsNewVMFormOpen}
            />
          }
          onRowClicked={handleRowClick}
          pointerOnHover={true}
        />

        {isDetailsOpen && selectedVM && (
          <VMDetails
            vm={selectedVM}
            refetchVMs={refetch}
            onClose={() => {
              setCurrentVMRowId("");
              setIsDetailsOpen(false);
            }}
          />
        )}
      </Wrapper>

      <ColumnsSelector
        open={isColumnsSelectorOpen}
        setOpen={setIsColumnsSelectorOpen}
        columns={columns}
        setColumns={setColumns}
      />

      <NewCustomerForm
        open={isNewCustomerFormOpen}
        setOpen={setIsNewCustomerFormOpen}
      />

      <NewVMForm
        open={isNewVMFormOpen}
        stats={stats}
        setOpen={setIsNewVMFormOpen}
        refetchVMs={refetch}
      />
    </>
  );
}
