import { useCustomer } from "providers/customer";
import React from "react";
import Spinner from "ui/Spinner";
import { SpinnerWrapper, Wrapper } from "./styles";


interface IProps {
  children: React.ReactNode;
}

export default function GlobalLayout({ children }: IProps) {
  const { customer } = useCustomer();

  const allowedPaths = [
    "/tool/customers/create",
    "/tool/customers/update",
    "/tool/settings/datacenters",
    "/tool/licenses/upload",
    "/api",
    "/tool/notifications",
    "/tool/login",
    "/tool/register",
    "/tool/2fa/verify",
    "/tool/2fa/setup",
  ];

  return (
    <Wrapper>
      {customer ||
        allowedPaths.find((one) => location.pathname.includes(one)) ? (
        children
      ) : (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
}
