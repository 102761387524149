import CreateCustomer from 'features/tool/modules/CreateCustomer';
import { UserProvider } from 'providers/user';
import React from 'react';

export default function ToolCreateCustomerPage() {
  return (
    <UserProvider>
      <CreateCustomer />
    </UserProvider>
  );
}
