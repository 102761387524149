import ScoCloudSvg from 'assets/svg/SCOCloudSVG';
import useValidate from 'features/tool/hooks/useValidate';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Button from 'ui/Button';
import ErrorMsg from 'ui/ErrorMsg';
import Label from 'ui/Label';
import Input from 'ui/TextField';
import api from 'utils/api';
import { getErrorMessage } from 'utils/parser';
import { FormWrapper, Title, Wrapper } from './styles';

type Form = {
  token: string;
};

export default function Verify() {
  const [form, setForm] = React.useState<Form>({ token: '' });
  const [verificationError, setVerificationError] = useState<string>();
  const { push } = useHistory();

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  const mutation = useMutation(() => api.post('/challenges/otp/verify', form), {
    onSuccess: ({ data }) => {
      if (data.success) push('/tool/customers/create');
      else setVerificationError('Invalid Code !');
    },
  });

  const {
    errors,
    handleBlur,
    handleFocus,
    setTouched,
    touched,
  } = useValidate();

  const isError = Object.keys(errors).length !== 0;

  return (
    <Wrapper>
      <ScoCloudSvg />
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <Title>Verification code</Title>
          {mutation.isError && (
            <ErrorMsg message={` ${getErrorMessage(mutation.error)}`} />
          )}
          <Label>Code</Label>
          <Input
            name="token"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors['token'] || verificationError}
            touched={touched['token']}
            required
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isError}
          >
            {mutation.isLoading ? (
              <Loader height={15} width={15} type="TailSpin" color="#FFF" />
            ) : (
              'Verify'
            )}
          </Button>
        </FormWrapper>
      </form>
    </Wrapper>
  );
}
