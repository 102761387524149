import useOutsideAlerter from "features/virtual/hooks/useOutsideAlerter";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { INotification } from "types/notifications";
import Badge from "ui/Badge";
import Button from "ui/Button";
import DropDown, { DropDownItem } from "ui/DropDown";
import Title from "ui/Title";
import api from "utils/api";
import {
  Wrapper,
  NotificationDate,
  NotificationHeader,
  NotificationItem,
} from "./styles";

interface INotificationProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Notifications({ open, setOpen }: INotificationProps) {
  const { data: notifications = [] } = useQuery("notifications", async () => {
    const { data } = await api.get("/tool/notifications/customer");
    return data as INotification[];
  });

  const localNotifications = localStorage.getItem("notifications");
  const parsedLocalNotifications = localNotifications
    ? JSON.parse(localNotifications)
    : [];

  const [readNotifications, setReadNotifications] = useState(
    parsedLocalNotifications
  );

  const notificationRef = useRef(null);
  useOutsideAlerter(notificationRef, setOpen);

  const count =
    notifications.length -
    notifications.filter((one) => readNotifications.includes(one._id)).length;

  const handleNotificationHover = (id: string) => {
    if (!readNotifications.includes(id)) {
      setReadNotifications([...readNotifications, id]);
    }
  };

  const handleMarkAllAsRead = () => {
    setReadNotifications(notifications.map((one) => one._id));
  };

  useEffect(() => {
    if (notifications.length === 0) return;
    localStorage.setItem("notifications", JSON.stringify(readNotifications));
  }, [readNotifications]);

  return (
    <Wrapper ref={notificationRef}>
      <Badge value={count} onClick={() => setOpen(!open)} />
      {open && (
        <DropDown id="" status="error" top={30} right={10} minWidth={50}>
          <NotificationHeader>
            <Title>Notifications {count > 0 && <span>({count})</span>}</Title>
            <Button
              variant="link"
              onClick={handleMarkAllAsRead}
              disabled={count === 0}
            >
              Mark all as read
            </Button>
          </NotificationHeader>
          <div>
            {notifications.map((item) => (
              <div key={item._id}>
                <hr />
                <DropDownItem
                  onMouseEnter={() => handleNotificationHover(item._id || "")}
                  key={item._id}
                >
                  <NotificationItem>
                    <span>{item.dataCenter.name} - </span>
                    <NotificationDate>
                      {moment(item.created_at).format("MM/DD/YYYY hh:mm a")}
                    </NotificationDate>
                    {item.message}
                  </NotificationItem>
                </DropDownItem>
              </div>
            ))}
          </div>
        </DropDown>
      )}
    </Wrapper>
  );
}
