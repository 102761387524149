import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  font-size: 1.8rem;
  cursor: pointer;
`;

interface IToggleWrapper {
  isOn: boolean;
}

export const ToggleWrapper = styled.div<IToggleWrapper>`
  display: flex;
  align-items: center;
  svg {
    ${({ theme, isOn }) => `
    color: ${isOn ? theme.palette.primary.main : "gray"};
    transform: ${isOn ? "scaleX(1)" : "scaleX(-1)"};
    `}
  }
`;

export const Label = styled.label`
  cursor: pointer;
  margin-right: .5rem;
`;
