import ScoCloudSvg from 'assets/svg/SCOCloudSVG';
import { AxiosError, AxiosResponse } from 'axios';
import useValidate from 'features/tool/hooks/useValidate';
import { useUser } from 'providers/user';
import React from 'react';
import Loader from 'react-loader-spinner';
import { useMutation } from 'react-query';
import { User } from 'types/authentication';
import Button from 'ui/Button';
import ErrorMsg from 'ui/ErrorMsg';
import Label from 'ui/Label';
import Input from 'ui/TextField';
import api from 'utils/api';
import { getErrorMessage } from 'utils/parser';
import { FormWrapper, Title, Wrapper } from './styles';

type Form = {
  username: string;
  password: string;
};

export default function Login() {
  const { fetchUser } = useUser();
  const [form, setForm] = React.useState<Form>({ username: '', password: '' });
  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  const mutation = useMutation<AxiosResponse<User>, AxiosError>(
    () => api.post('/auth/signin?$select=name,email,iams', form),
    {
      /* eslint-disable */
      onSuccess: ({}) => {
        fetchUser();
      },
    }
  );

  const {
    errors,
    handleBlur,
    handleFocus,
    setTouched,
    touched,
  } = useValidate();

  const isError = Object.keys(errors).length !== 0;

  return (
    <Wrapper>
      <ScoCloudSvg />
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <Title>Sign in to Continue</Title>
          {mutation.isError && (
            <ErrorMsg message={` ${getErrorMessage(mutation.error)}`} />
          )}
          <Label>Username or Email*</Label>
          <Input
            name="username"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors['username']}
            touched={touched['username']}
            required
          />

          <Label>Password*</Label>
          <Input
            type="password"
            name="password"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors['password']}
            touched={touched['password']}
            required
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isError}
          >
            {mutation.isLoading ? (
              <Loader height={15} width={15} type="TailSpin" color="#FFF" />
            ) : (
              'Login'
            )}
          </Button>
        </FormWrapper>
      </form>
    </Wrapper>
  );
}
