import React from "react";

type VMProps = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function VM({
  fill = "#000",
  width = 20,
  height = 20,
}: VMProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.352 22.347"
    >
      <g id="data" transform="translate(-1.002 -0.108)">
        <g id="Group_9" data-name="Group 9" transform="translate(1.002 0.108)">
          <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
            <path
              id="Path_55"
              data-name="Path 55"
              d="M23.4,6.736a.37.37,0,0,0-.043-.118l0,0h0a.371.371,0,0,0-.088-.1L23.234,6.5,23.2,6.475,18.732,4.24l-.039-.011a.367.367,0,0,0-.073-.02.4.4,0,0,0-.139.006.357.357,0,0,0-.071.026l-.039.014-5.394,3.3V2.71c0-.012,0-.023,0-.034a.358.358,0,0,0-.005-.038.37.37,0,0,0-.043-.118l0,0h0a.371.371,0,0,0-.088-.1L12.8,2.4l-.031-.022L8.3.142,8.262.131a.36.36,0,0,0-.073-.02A.4.4,0,0,0,8.05.117a.365.365,0,0,0-.072.026L7.94.157l-6.706,4.1h0a.369.369,0,0,0-.095.088l-.019.026a.369.369,0,0,0-.052.113s0,.01,0,.015a.37.37,0,0,0-.01.076V15.749a.372.372,0,0,0,.206.333l4.471,2.235.01,0a.374.374,0,0,0,.156.037h0a.371.371,0,0,0,.1-.014l.039-.017a.362.362,0,0,0,.055-.024l5.394-3.3v4.842a.372.372,0,0,0,.206.333l4.471,2.235.01,0a.374.374,0,0,0,.156.037h0a.37.37,0,0,0,.1-.014c.009,0,.017-.008.025-.011a.371.371,0,0,0,.068-.03l6.706-4.1a.353.353,0,0,0,.031-.029.318.318,0,0,0,.095-.11.358.358,0,0,0,.029-.063.365.365,0,0,0,.015-.076.349.349,0,0,0,.008-.041V6.808c0-.012,0-.023,0-.034A.327.327,0,0,0,23.4,6.736ZM5.527,17.382,1.8,15.519V10.764l3.725,1.863Zm0-5.588L1.8,9.931V8.9l3.725,1.863Zm0-1.863L1.8,8.068V7.039L5.527,8.9Zm0-1.863L1.8,6.206V5.176L5.527,7.039Zm.354-1.685L2.2,4.54,8.152.9l3.684,1.842Zm5.785,1.97h0a.369.369,0,0,0-.095.088l-.019.026a.369.369,0,0,0-.052.113s0,.01,0,.015a.367.367,0,0,0-.01.076v5.462L6.272,17.32V7.017l5.961-3.643V8.007ZM15.958,21.48l-3.725-1.863V14.862l3.725,1.863Zm0-5.588-3.725-1.863V13l3.725,1.863Zm0-1.863-3.725-1.863v-1.03L15.958,13Zm0-1.863L12.232,10.3V9.274l3.725,1.863Zm.354-1.686L12.628,8.638,18.584,5l3.684,1.842Zm6.351,7.295L16.7,21.418v-10.3l5.961-3.643Z"
              transform="translate(-1.056 -0.108)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
