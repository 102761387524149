import React, { ButtonHTMLAttributes, ElementType } from "react";
import { Wrapper } from "./styles";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: "contained" | "outlined" | "text" | "link";
  color?: "default" | "primary" | "secondary";
  htmlFor?: string;
  /* eslint-disable */
  as?: ElementType<any> | undefined;
}

export default function Button({
  children,
  variant = "contained",
  color = "primary",
  ...rest
}: ButtonProps) {
  return (
    <Wrapper variant={variant} color={color} {...rest}>
      {children}
    </Wrapper>
  );
}
