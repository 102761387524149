import ScoCloudSvg from "assets/svg/SCOCloudSVG";
import { AxiosError, AxiosResponse } from "axios";
import useValidate from "features/tool/hooks/useValidate";
import { useUser } from "providers/user";
import React from "react";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { User } from "types/authentication";
import Button from "ui/Button";
import ErrorMsg from "ui/ErrorMsg";
import Label from "ui/Label";
import Input from "ui/TextField";
import api from "utils/api";
import { getErrorMessage } from "utils/parser";
import { FormWrapper, Title, Wrapper } from "./styles";

type Form = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  username: string;
  phone: string;
};

export default function Register() {
  const { signIn } = useUser();
  const history = useHistory();
  const [form, setForm] = React.useState<Form>({
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const {
    errors,
    handleBlur,
    handleFocus,
    setTouched,
    touched,
  } = useValidate();

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const mutation = useMutation<AxiosResponse<User>, AxiosError>(
    () => {
      const { firstName, lastName, ...rest } = form;
      return api.post("/auth/signup", {
        ...rest,
        name: { first: firstName, last: lastName },
      });
    },
    {
      onSuccess: ({ data }) => {
        signIn(data);
        history.push({ pathname: "/" });
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  const isError = Object.keys(errors).length !== 0;

  return (
    <Wrapper>
      <ScoCloudSvg />
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          <Title>Sign up to Continue</Title>
          {mutation.isError && (
            <ErrorMsg message={` ${getErrorMessage(mutation.error)}`} />
          )}

          <Label>First Name*</Label>
          <Input
            name="firstName"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["firstName"]}
            touched={touched["firstName"]}
            required
          />

          <Label>Last Name*</Label>
          <Input
            name="lastName"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["lastName"]}
            touched={touched["lastName"]}
            required
          />

          <Label>Username*</Label>
          <Input
            name="username"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["username"]}
            touched={touched["username"]}
            required
          />

          <Label>Password*</Label>
          <Input
            type="password"
            name="password"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["password"]}
            touched={touched["password"]}
            required
          />

          <Label>Email*</Label>
          <Input
            name="email"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={errors["email"]}
            touched={touched["email"]}
            required
          />

          <Label>Phone</Label>
          <Input name="phone" autoComplete="off" onChange={handleChange} />

          <Button
            type="submit"
            style={{ marginTop: "16px" }}
            color="primary"
            variant="contained"
            disabled={isError}
          >
            {mutation.isLoading ? (
              <Loader height={15} width={15} type="TailSpin" color="#FFF" />
            ) : (
              "Register"
            )}
          </Button>
          <Link to="/tool/login">
            <Button variant="text" type="button">
              Already have an account? Sign in
            </Button>
          </Link>
        </FormWrapper>
      </form>
      ``
    </Wrapper>
  );
}
