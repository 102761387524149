import React, { useState } from "react";
import { QueryObserverResult, RefetchOptions, useMutation } from "react-query";
import { IVM } from "types/virtual";
import Button from "ui/Button";
import Modal from "ui/Modal";
import SnackBar from "ui/SnackBar";
import Spinner from "ui/Spinner";
import TextField from "ui/TextField";
import api from "utils/api";
import { getErrorMessage } from "utils/parser";
import { ActionsWrapper, Wrapper } from "./styles";

interface IProps {
  vm: IVM;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchVMs: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IVM[], unknown>>;
}

export default function EditSettingsForm({
  vm,
  open,
  setOpen,
  refetchVMs,
}: IProps) {
  const initForm = { cpu: vm.numberOfCpus, ram: vm.memoryMB / 1024 };
  const [form, setForm] = useState(initForm);

  const mutation = useMutation(
    () =>
      api.put(`/vcloud-director/virtual/vms/${vm.id}`, {
        cpu: form.cpu,
        ram: form.ram,
      }),
    {
      onSuccess: () => {
        setOpen(false);
        setForm(initForm);
        refetchVMs();
      },
    }
  );

  const handleChange = ({
    currentTarget: { value, name },
  }: React.FormEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Modal open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
      <Wrapper onSubmit={handleSubmit}>
        {mutation.isError && (
          <SnackBar type="error" message={getErrorMessage(mutation.error)} />
        )}
        {mutation.isLoading ? (
          <Spinner width={50} />
        ) : (
          <>
            <TextField
              name="cpu"
              type="number"
              value={form.cpu}
              label="Number of CPU's"
              autoComplete="off"
              required
              onChange={handleChange}
            />
            <TextField
              name="ram"
              type="number"
              value={form.ram}
              label="Memory (GB)"
              autoComplete="off"
              required
              onChange={handleChange}
            />
            <ActionsWrapper>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </Button>
              <Button variant="outlined" type="submit">
                Edit
              </Button>
            </ActionsWrapper>
          </>
        )}
      </Wrapper>
    </Modal>
  );
}
